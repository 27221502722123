<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                Статистика социальных сетей в разрезе товаров
                <v-btn class="ml-5" color="primary" :loading="loading_export" @click="export_stats">
                    Экспорт статистики
                </v-btn>
            </v-card-title>
            <v-card-text ref="social_items_filters">
                <div class="d-flex mt-5">
                    <div style="display: flex; flex-direction: column;">
                        <v-menu 
                            v-model="datepicker_menu_create" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_create"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataCreateRangeText" label="Период добавления ролика"
                                    prepend-icon="mdi-calendar" readonly="readonly" 
                                    @click:clear="dates_create_range = []"
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dates_create_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu 
                            v-model="datepicker_menu_stats" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_stats"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataStatsRangeText" label="Период статистики"
                                    prepend-icon="mdi-calendar" readonly="readonly" class="mt-3"
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                    @click:clear="dates_stats_range = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dates_stats_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-autocomplete
                            v-model="filters.manager" :items="choices.manager_choices" multiple
                            label="Менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                        <v-autocomplete
                            v-model="filters.tutor" :items="choices.tutor_choices" class="mt-3" multiple
                            label="Куратор" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-select
                            v-model="filters.resource" :items="choices.resource_choices" multiple
                            item-text="name" item-value="id" label="Площадка" outlined hide-details dense clearable
                        ></v-select>
                        <v-autocomplete
                            v-model="filters.product_group" :items="choices.products_group_choices" class="mt-3" multiple
                            label="Проект" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-text-field
                            v-model="filters.products_name" label="Название" 
                            outlined clearable dense hide-details
                        ></v-text-field>
                        <v-text-field
                            v-model="filters.products" label="Артикул" 
                            outlined clearable dense class="mt-3" hide-details
                        ></v-text-field>
                    </div>
                    <v-btn 
                        color="primary" class="ml-5" :loading="loading" @click="list_stats"
                    >Применить фильтры</v-btn>
                </div>
                <v-data-table
                    :headers="headers" :pageCount="pagination.numberOfPages" :items="data"
                    item-key="id" :loading="loading" :options.sync="options"
                    :server-items-length="pagination.totalItems" class="elevation-1 mt-5"
                    :footer-props="{'items-per-page-options': [10, 25]}"
                >
                    <template v-slot:item.photo="{ item }">
                        <img style="max-height:80px" :src="item.image" alt="Изображение товара">
                    </template>
                    <template v-slot:item.product="{ item }">
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <router-link 
                                target="_blank" :to="{ name: 'product_card', params: { id: item.nmid }}"
                                v-if="permissions != 'manager'" class="d-block" style="text-align: center;"
                            >{{ item.nmid }}</router-link>
                            <div v-else class="d-block" style="text-align: center;">{{ item.nmid }}</div>
                            <a :href="`https://www.wildberries.ru/catalog/${item.nmid}/detail.aspx`" target="_blank" style="display: block; text-align: center;">{{ item.name }}</a>
                        </div>
                    </template>
                    <template v-slot:item.graphs_orders="{ item }">
                        <div style="max-width: 150px; width: 150px">
                            <SocialParserCharts :data="item.graphs_orders" :name="'Заказы'" :key="`orders_${item.nmid}_${counter}`"/>
                        </div>
                    </template>
                    <template v-slot:item.graphs_views="{ item }">
                        <div style="max-width: 150px; width: 150px">
                            <SocialParserCharts :data="item.graphs_views" :name="'Просмотры'" :key="`views_${item.nmid}_${counter}`"/>
                        </div>
                    </template>
                    <template v-slot:item.graphs_frq="{ item }">
                        <div style="max-width: 150px; width: 150px">
                            <SocialParserCharts :data="item.graphs_frq" :name="'Частотность'" :key="`frq_${item.nmid}_${counter}`"/>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { SocialParser } from '@/api/social_parser';
    import SocialParserCharts from '@/views/SocialParser/SocialParserCharts.vue';

	export default {
		name: 'SocialParserBasePage',
        components: {
            SocialParserCharts
        },
		data() {
			return {
                counter: 0,
                permissions: '',
                loading: false,
                loading_export: false,
                headers: [
                    { text: 'Фото товара', value: 'photo', width: '1%', sortable: false, align: 'center'},
                    { text: 'Товар', value: 'product', width: '3%', sortable: false, align: 'center'},
                    { text: 'Кол-во просмотров за период', value: 'total_views', width: '1%', sortable: true, align: 'center' },
                    { text: 'Переходов на ВБ(частотность)', value: 'total_frq', width: '1%', sortable: true, align: 'center' },
                    { text: 'Кол-во видео', value: 'video_count', width: '1%', sortable: true, align: 'center' },
                    { text: 'Сумма заказов за период', value: 'total_orders', width: '1%', sortable: true, align: 'center' },
                    { text: 'График заказов', value: 'graphs_orders', width: '3%', sortable: false, align: 'center' },
                    { text: 'График просмотров', value: 'graphs_views', width: '3%', sortable: false, align: 'center' },
                    { text: 'График частотности', value: 'graphs_frq', width: '3%', sortable: false, align: 'center' },
                ],
                data: [],
                options: {
                    sortBy: ['date_created'],
                    sortDesc: [true],
                    itemsPerPage: 25
                },
                pagination: {
                    next: null,
                    totalItems: 0,
                    numberOfPages: 0,
                    currentPage: 0
                },
                filters: {
                    manager: null,
                    tutor: null,
                    resource: null,
                    product_group: null,
                    products_name: null,
                    products: null
                },
                choices: {
                    manager_choices: [],
                    tutor_choices: [],
                    resource_choices: [],
                    products_group_choices: []
                },
                datepicker_menu_create: false,
                datepicker_menu_stats: false,
                dates_create_range: [],
                dates_stats_range: [],
            }
		},
        watch: {
            'options': async function (newVal, oldVal) {
				if (oldVal) {
					await this.list_stats()
				}
			},
        },
        computed: {
            dataCreateRangeText() {
				return this.dates_create_range.join(' ~ ');
			},
            dataStatsRangeText() {
				return this.dates_stats_range.join(' ~ ');
			},
            transfromFiltersForQuery(){
                let query = {}
                for (const f in this.filters) {
                    if (Array.isArray(this.filters[f])) {
                        query[f] = this.filters[f].join(',')
                    } else {
                        query[f] = this.filters[f]
                    }
                }
                return query
            },
        },
		methods: {
            async fetch_choices() {
                try {
                    const response = await SocialParser.ListManagers()
                    let data = response.data
                    this.choices.manager_choices = data.managers
                    this.choices.products_group_choices = data.products_group
                    this.choices.resource_choices = data.resources
                    this.choices.tutor_choices = data.tutors
                    let current_user = Array.from(this.create.manager_choices)
                                            .find(obj => obj.id == this.$store.getters.userPK)
                    if (current_user)
                        this.filters.manager = current_user.id
                } catch (err) {
                    console.log(err.response)
                }
            },
            async get_permissions(){
                try{
                    const response = await SocialParser.socialPermissions()
                    this.permissions = response.data.permission
                } catch (err){
                    console.log(err)
                }
            },
            async list_stats(){
                this.loading = true
				this.$vuetify.goTo(this.$refs.social_items_filters, {
                    duration: 500, offset: 150, easing: 'linear',
                })
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				try {
					let query_data = {
						...this.transfromFiltersForQuery,
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort_additional': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}
                    if (this.dates_create_range.length > 0){
                        if (this.dates_create_range.length == 1)
                            query_data['date_created_after'] = this.dates_create_range[0]
                        else {
                            query_data['date_created_after'] = this.dates_create_range[0]
                            query_data['date_created_before'] = this.dates_create_range[1]
                        }
                        
                    }
                    if (this.dates_stats_range.length > 0){
                        if (this.dates_stats_range.length == 1)
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                        else {
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                            query_data['date_stats_before'] = this.dates_stats_range[1] 
                        }
                    }
                    const response = await SocialParser.socialStatsByProducts(query_data)
					this.data = response.data.results
					this.pagination.totalItems = response.data.count
					this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                    this.counter += 1
				} catch (err) {
                    this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
					console.log(err.data)
				}
				this.loading = false
            },
            async export_stats(){
                if (this.dates_stats_range.length != 2){
                    this.$toasted.show(`Заполните начальную и конечную дату статистики`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    return
                }
                this.loading_export = true
				try {
                    let query_data = {
						...this.transfromFiltersForQuery,
					}
                    if (this.dates_create_range.length > 0){
                        if (this.dates_create_range.length == 1)
                            query_data['date_created_after'] = this.dates_create_range[0]
                        else {
                            query_data['date_created_after'] = this.dates_create_range[0]
                            query_data['date_created_before'] = this.dates_create_range[1]
                        }
                        
                    }
                    if (this.dates_stats_range.length > 0){
                        if (this.dates_stats_range.length == 1)
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                        else {
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                            query_data['date_stats_before'] = this.dates_stats_range[1] 
                        }
                    }
					const response = await SocialParser.socialStatsByProductsExport(query_data)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'social_items_product_stats.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
                    this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
				}
                this.loading_export = false
			},
		},
		async mounted() {
			document.title = 'Статистика социальных сетей по товарам';
			this.$store.commit('setPageTitle', `Статистика социальных сетей по товарам`)
            await this.get_permissions()
            await this.fetch_choices()
		},
	};
</script>

<style lang="scss">
</style>