<template>
    <apexchart type="line" height="90" :options="options" :series="series"></apexchart>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'SocialParserCharts',
        components: {apexchart: VueApexCharts},
		props: {
            data: {
                type: Array,
                default() { return[] },
                require: true
            },
            name: {
                type: String,
                require: true
            }
		},
        data() {
            return {
                options: {
                    theme: {
                        mode: this.$vuetify.theme.dark ? 'dark': 'light'
                    },
                    chart: {
                        height: 100, width: '100%', type: 'bar', zoom: {enabled: false}, toolbar: {show: false},
                        background: 'rgba(0, 0, 0, 0)'
                    },
                    stroke: {show: false, width: 0},
                    colors: ['#5bb95e'],
                    dataLabels: {enabled: false},
                    yaxis: {show: false},
                    xaxis: {show: false, labels: {show: false}},
                    tooltip: { 
                        fixed:{ enabled: true, offsetX: -100 } 
                    },
                    grid: {
                        show: false, row: {colors: ['#f3f3f3', 'transparent'], opacity: 0},
                    },
                },
                series: null
            };
        },
		created(){
            let tmp_data = this.data.map(item => ({x: item.date, y: item.count}))
            this.series = [
                {name: this.name, type: 'bar', data: tmp_data},
            ]
		},
    };
</script>

<style scoped>

</style>