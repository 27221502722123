<template>
    <v-container fluid grey lighten-4 class="pa-5 table_template_management">
		<v-dialog v-model="exists_promotion.dialog" v-if="exists_promotion.dialog" max-width="1200" scrollable>
			<v-card>
				<v-card-title>Текущие акции ВБ акций ({{ exists_promotion.number }})</v-card-title>
				<v-card-text>
					<v-data-table 
						:headers="exists_promotion.headers" 
						:items="exists_promotion.data" 
						:loading="exists_promotion.loading" 
						class="elevation-1"
					>
						<template v-slot:item.date_from="{ item }">
							{{ item.date_from }} - {{ item.date_to }}
						</template>
						<template v-slot:item.is_actual="{ item }">
							<v-icon small color="green" v-if="!item.is_completed">mdi-checkbox-marked-circle</v-icon>
							<v-icon v-else color="red" small>mdi-close-circle</v-icon>
						</template>
						<template v-slot:item.action="{ item }">
							<v-btn icon :loading="exists_promotion.loading_add" @click="addExistPromotionToTemplate(item.id)"><v-icon>mdi-plus</v-icon></v-btn>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="search_promotion.dialog" v-if="search_promotion.dialog" max-width="1200" scrollable>
			<v-card>
				<v-card-title>Поиск акций ({{ search_promotion.number }})</v-card-title>
				<v-card-text>
					<div class="mt-5 d-flex align-center">
						<v-text-field v-model="search_promotion.search" clearable outlined dense label="Название акции" hide-details="hide-details"></v-text-field>
						<v-btn depressed color="purple darken-2" class="mb-0 ml-3" dark @click="searchPromotion">Поиск</v-btn>
					</div>
					<v-data-table 
						:headers="search_promotion.headers" 
						:items="search_promotion.data" 
						:loading="search_promotion.loading" 
						class="elevation-1"
					>
						<template v-slot:item.action="{ item }">
							<v-btn icon :loading="search_promotion.loading_set" @click="selectPromotionFromOutside(item.id)"><v-icon>mdi-check</v-icon></v-btn>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="promotion_update.dialog" v-if="promotion_update.dialog" max-width="1200" scrollable>
			<v-card>
				<v-card-title>Загрузка файла</v-card-title>
				<v-card-text>
					<v-file-input
						label="Файл акции"
						outlined
						dense
						id="promotion_file_update"
						class="mt-5"
						hide-details
					></v-file-input>
				</v-card-text>
				<v-card-actions>
					<v-btn
						@click="updatePromotion"
						color="primary"
						dark
						class="mb-2"
						:loading="promotion_update.loading"
					>Загрузить</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="promotion_create.dialog"
			v-if="promotion_create.dialog"
			max-width="1200"
			scrollable
		>
			<v-card>
				<v-card-title>Добавление акции {{promotion_create.number}}</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="promotion_create.form.name"
						clearable
						class="mt-5"
						outlined
						dense
						label="Название акции"
						hide-details="hide-details">
					</v-text-field>
					<v-text-field
						v-model="promotion_create.form.is_entered_to_promotion"
						clearable
						type="number"
						class="mt-5"
						outlined
						dense
						label="Если зашли в акцию"
						hide-details="hide-details">
					</v-text-field>
					<v-text-field
						v-model="promotion_create.form.is_not_entered_to_promotion"
						clearable
						type="number"
						class="mt-5"
						outlined
						dense
						label="Если не зашли в акцию"
						hide-details="hide-details">
					</v-text-field>
					<v-text-field
						v-model="promotion_create.form.minimum_commission"
						clearable
						class="mt-5"
						type="number"
						outlined
						dense
						label="Минимальная комиссия"
						hide-details="hide-details">
					</v-text-field>
					<v-file-input
						label="Файл акции"
						outlined
						dense
						id="promotion_file"
						class="mt-5"
						hide-details
					></v-file-input>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="primary"
						dark
						class="mb-2"
						@click="createPromotion"
						:loading="promotion_create.loading"
					>Сохранить</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="promotion.dialog"
			v-if="promotion.dialog"
			max-width="1200"
			scrollable
		>
			<v-card>
				<v-card-title>Акции</v-card-title>
				<v-card-text>
					<v-select
						v-model="promotion.choices_account"
						:items="promotion.list_wbaccount"
						item-text="name"
						item-value="value"
						class="mt-5"
						label="ЛК"
						outlined
						hide-details
						dense
						clearable
					></v-select>
					<div class="block_promotion">
						<div class="block_promotion__element">
							<h2>Акция 1: </h2>
							<div v-if="promotion.first_promotion" class="ml-5 d-flex" style="align-items:center;">
								<span>
									{{promotion.first_promotion.name ? promotion.first_promotion.name : "Неизвестное название"}}
								</span>
								<v-btn 
									@click="openDialogUpdatePromotion(promotion.first_promotion.id)"
									dense
									class="ml-3"
									v-if="Object.keys(promotion.first_promotion.terms_of_promotion).length == 0"
								>Загрузить файл</v-btn>
								<v-btn 
									class="ml-5 delete_button"
									depressed
									@click="deletePromotion(promotion.first_promotion.id)"
								>Удалить</v-btn>
								<v-btn 
									class="ml-5"
									:loading="copy_promotion_loading"
									depressed
									@click="copyPromotion(promotion.first_promotion.id)"
								>Дублировать</v-btn>
							</div>
							<div v-else>
								<v-btn class="ml-5" depressed @click="openDialogCreatePromotion(1)">+</v-btn>
								<v-btn class="ml-5" icon @click="openDialogSearchPromotion(1)"><v-icon>mdi-magnify</v-icon></v-btn>
								<v-btn class="ml-5" depressed @click="openExistsPromotions(1)">Добавить существующую акцию</v-btn>
							</div>
							
						</div>
						<div class="block_promotion__element">
							<h2>Акция 2: </h2>
							<div v-if="promotion.second_promotion" class="ml-5 d-flex" style="align-items:center;">
								<span>
									{{promotion.second_promotion.name ? promotion.second_promotion.name : "Неизвестное название"}}
								</span>
								<v-btn 
									@click="openDialogUpdatePromotion(promotion.second_promotion.id)"
									dense
									class="ml-3"
									v-if="Object.keys(promotion.second_promotion.terms_of_promotion).length == 0"
								>Загрузить файл</v-btn>
								<v-btn 
									class="ml-5 delete_button"
									depressed
									@click="deletePromotion(promotion.second_promotion.id)"
								>Удалить</v-btn>
								<v-btn 
									class="ml-5"
									depressed
									:loading="copy_promotion_loading"
									@click="copyPromotion(promotion.second_promotion.id)"
								>Дублировать</v-btn>
							</div>
							<div v-else>
								<v-btn class="ml-5" depressed @click="openDialogCreatePromotion(2)">+</v-btn>
								<v-btn class="ml-5" icon @click="openDialogSearchPromotion(2)"><v-icon>mdi-magnify</v-icon></v-btn>
								<v-btn class="ml-5" depressed @click="openExistsPromotions(2)">Добавить существующую акцию</v-btn>
							</div>
						</div>
						<div class="block_promotion__element">
							<h2>Акция 3: </h2>
							<div v-if="promotion.third_promotion" class="ml-5 d-flex" style="align-items:center;">
								<span>
									{{promotion.third_promotion.name ? promotion.third_promotion.name : "Неизвестное название"}}
								</span>
								<v-btn 
									@click="openDialogUpdatePromotion(promotion.third_promotion.id)"
									dense
									class="ml-3"
									v-if="Object.keys(promotion.third_promotion.terms_of_promotion).length == 0"
								>Загрузить файл</v-btn>
								<v-btn 
									class="ml-5 delete_button"
									depressed
									@click="deletePromotion(promotion.third_promotion.id)"
								>Удалить</v-btn>
								<v-btn 
									class="ml-5"
									depressed
									:loading="copy_promotion_loading"
									@click="copyPromotion(promotion.third_promotion.id)"
								>Дублировать</v-btn>
							</div>
							<div v-else>
								<v-btn class="ml-5" depressed @click="openDialogCreatePromotion(3)">+</v-btn>
								<v-btn class="ml-5" icon @click="openDialogSearchPromotion(3)"><v-icon>mdi-magnify</v-icon></v-btn>
								<v-btn class="ml-5" depressed @click="openExistsPromotions(3)">Добавить существующую акцию</v-btn>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="update_price_discount.dialog_pre_update_price_discount"
			v-if="update_price_discount.dialog_pre_update_price_discount"
			max-width="1200"
			scrollable
		>
			<v-card>
				<v-card-title>
                    Просмотр изменений
                    <v-icon
                        v-bind="attrs" 
                        v-on="on" 
                        v-if="update_price_discount.checked"
                        color="green"
                    >mdi-checkbox-marked-circle</v-icon>
                    <v-icon
                        v-bind="attrs" 
                        v-on="on" 
                        v-else
                        color="red"
                    > mdi-pause-circle</v-icon>
                </v-card-title>
				<v-card-text>
					<v-simple-table dense class="small-font vertical-border elevation-1">
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">id</th>
									<th class="text-left">Аккаунт</th>
									<th class="text-left">Артикул</th>
									<th class="text-left">Цена без скидки</th>
									<th class="text-left">Скидка</th>
									<th class="text-left">Старая цена со скидкой</th>
									<th class="text-left">Цена со скидкой</th>
									<th class="text-left">Цена с СПП</th>
									<th class="text-left">Себестоимость</th>
									<th class="text-left">Что изменится</th>
									<th class="text-left">Проверка</th>
								</tr>
							</thead>
							<tbody>
							<tr 
								v-for="item in update_price_discount.pre_update_items" 
								:key="item.id"
								:class="{
									'orange_checked': item.old_finally_price && item.final_price && Math.abs(item.final_price - item.old_finally_price / item.final_price * 100) > 15, 
									'red_checked': item.cost_price > 0 && item.final_price < item.cost_price}"
								>
								<td>{{ item.id }}</td>
								<td>{{ item.account }}</td>
								<td>{{ item.nmid }}</td>
								<td>{{ item.price_api_cost }}</td>
								<td>{{ item.price_api_sales }}</td>
								<td>{{ item.old_finally_price }}</td>
								<td>{{ item.final_price }}</td>
								<td>{{ item.spp }}</td>
								<td>{{ item.cost_price }}</td>
								<td>
                                    <p v-if="item.change_price">Изменение цены</p>
                                    <p v-if="item.change_discount">Изменение скидки</p>
                                </td>
                                <td>
									<v-icon
                                        v-if="item.checked && item.cost_price < 1"
                                        color="yellow"
                                    >mdi-checkbox-marked-circle</v-icon>
                                    <v-icon
                                        v-else-if="item.checked"
                                        color="green"
                                    >mdi-checkbox-marked-circle</v-icon>
                                    <v-icon
                                        v-else
                                        color="red"
                                    > mdi-pause-circle</v-icon>
                                </td>
							</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer v-if="(!allCheckedSuccessfully || !update_price_discount.checked)"></v-spacer>
					<v-btn
						v-if="(!allCheckedSuccessfully || !update_price_discount.checked)"
						color="blue darken-2"
                        class="mr-2"
						depressed
                        dark
                        @click="checkPriceDiscountUpdate"
                        :loading="update_price_discount.loading_check"
					>Проверить</v-btn>
					<div v-else class="mr-2 d-flex" style="justify-content: space-between; width:100%; align-items: center;">
						<div class="d-flex" style="align-items:center;">
							<div>
								<v-menu 
									v-model="datePicker.datepicker_date" 
									:close-on-content-click="false"  
									prepend-icon="mdi-calendar"
									transition="scale-transition" 
									offset-y="offset-y"
									nudge-right="40" 
									min-width="290px"
									ref="datePicker.datepicker_date"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											clearable
											@click:clear="update_price_discount.date_update = null"
											v-model="dateFormatted"
											label="Дата"
											readonly="readonly"
											v-bind="attrs"
											hide-details
											outlined
											v-on="on"
											dense
										></v-text-field>
									</template>
									<v-date-picker 
										v-model="update_price_discount.date_update" 
										@input="datePicker.datepicker_date = false"
										:first-day-of-week="1"
									></v-date-picker>
								</v-menu>
								<v-menu 
									v-model="datePicker.datepicker_time" 
									:close-on-content-click="false"  
									prepend-icon="mdi-calendar"
									transition="scale-transition" 
									offset-y="offset-y"
									nudge-right="40" 
									min-width="290px"
									ref="datePicker.datepicker_time"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											clearable
											@click:clear="update_price_discount.time_update = null"
											v-model="timeFormatted"
											label="Время"
											readonly="readonly"
											class="mt-3"
											v-bind="attrs"
											hide-details
											outlined
											v-on="on"
											dense
										></v-text-field>
									</template>
									<v-time-picker 
										ampm-in-title
										v-model="update_price_discount.time_update" 
										format="24hr"
									></v-time-picker>
								</v-menu>
							</div>
							<v-btn 
								color="blue darken-2"
								class="ml-3"
								@click="createDelayedTask"
								:disabled="!update_price_discount.date_update || !update_price_discount.time_update"
								depressed
								dark
							>Обновить по времени</v-btn>
						</div>
						<v-btn 
							color="blue darken-2"
							@click="updatePriceDiscount"
							depressed
							dark
						>Обновить сейчас</v-btn>
					</div>
					
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="editing_data.dialog_editing_data"
			v-if="editing_data.dialog_editing_data"
			max-width="1200"
		>
			<v-card>
				<v-card-title>Массовое редактирование</v-card-title>
				<v-card-text>
					<v-select
						v-model="editing_data.select_type"
						:items="editing_data.edition_dict"
						item-text="text"
						item-value="id"
						class="mt-5"
						label="Поле"
						outlined
						hide-details
						dense
						clearable
					></v-select>
					<v-text-field
						v-model="editing_data.new_value"
						clearable
						type="number"
						class="mt-5"
						outlined
						dense
						label="Новое значение"
						hide-details="hide-details">
					</v-text-field>
					<v-btn
						depressed
						color="blue darken-2 mt-5"
						style="font-size: .9em;"
						dark
						@click="bulkEditing"
					>Обновить</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_alert" v-if="dialog_alert" max-width="1200">
			<v-card>
				<v-card-title>Предупреждение</v-card-title>
				<v-card-text>
					<v-alert density="compact" type="warning">
						Не изменяйте цену и скидки на большие значения. Может случиться ситуация, когда одно из значений не применится.
						Также следите за корректностью значений при подтверждении цены и скидки.
					</v-alert>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_competitors" max-width="1000px" v-if="dialog_competitors">
			<v-card>
				<v-card-title class="text-h5 justify-center">Конкуренты</v-card-title>
				<v-card-text> 
					<div v-if="!competitors_data || competitors_data.length < 1">
						Для данного товара не добавлены конкуренты
					</div>
					<div v-else>
						<v-data-table
							:headers="competitors_header"
							:items="competitors_data"
							item-key="id"
							class="elevation-1"
							disable-pagination
							hide-default-footer
							fixed-header
							height="600px"
						>
							<template v-slot:item.photo="{ item }">
								<img style="max-height:100px" :src="getImgPath(item.nmid)" alt="">
							</template>
							<template v-slot:item.nmid="{ item }">
								<a :href="`https://www.wildberries.ru/catalog/${item.nmid}/detail.aspx`" target="_blank">
									{{ item.nmid }}
								</a>
							</template>
							<template v-slot:item.chart_price="{ item }">
								<div style="height: 100px; width: 500px; margin: 0 auto;">
									<CompetitorsPriceChart :data_price="item.price_array"/>
								</div>
							</template>
							<template v-slot:item.last_data="{ item }">
								Цена: {{ item.last_data && item.last_data.price_with_spp ? item.last_data.price_with_spp : 0 | toRUB}} <br>
								СПП: {{ item.last_data && item.last_data.percent_spp ? item.last_data.percent_spp : 0}}%
							</template>
						</v-data-table>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-menu
			v-model="show_color_picker_cell"
			:position-x="x"
			:position-y="y"
			absolute
			offset-y
		>
			<v-color-picker
				v-if="show_color_picker_cell"
				hide-sliders
				hide-inputs
				show-swatches
				swatches-max-height="100"
				v-model="color"
				@update:color="setColor"
			></v-color-picker>
			<div class="reset_color_template_management" @click="resetColorCell">Нет заливки</div>
		</v-menu>
		<div class="d-flex align-center justify-space-between">
			<div>
				<h2 class="d-flex">
					{{general_data.name}}
					<router-link class="ml-3" target="_blank" :to="{name: 'management_product_delayed_task'}">
						<v-icon title="Отложенные задачи">mdi-timetable</v-icon>
					</router-link>
					<v-icon title="Заливка" class="ml-3" v-if="table.selected_items.length > 0" @click="openRowFillColorPicker">
						mdi-format-color-fill
					</v-icon>
					<v-btn icon class="ml-3" title="отменить действие" @click="ctrlZ">
						<v-icon>mdi-arrow-u-left-top</v-icon>
					</v-btn>
					<v-switch 
						color="primary" class="ml-5 mt-0" hide-details :label="`Автообновление`"
						v-model="general_data.is_auto_update" @change="change_auto_update()" dense
					></v-switch>
					<v-switch 
						color="primary" class="ml-5 mt-0" hide-details :label="`Открыт для всех`"
						v-model="general_data.is_open" @change="change_is_open()" dense
					></v-switch>
					<v-menu v-model="show_color_picker_row" :position-x="x" :position-y="y" absolute offset-y>
						<v-color-picker
							v-if="show_color_picker_row"
							hide-sliders
							hide-inputs
							show-swatches
							swatches-max-height="100"
							v-model="color_row"
							@input="setColorToRow"					
						></v-color-picker>
					</v-menu>
				</h2>
				<p v-if="general_data.last_update">
					Последнее сохранение: {{general_data.last_update | formatDateToLocale}}
					<span v-if="saving_flag"> | сохранение ...</span>
				</p>
			</div>
			<div>
				<v-row>
                    <v-col>
                        <v-text-field
                            v-model="activeFilters.code_1c" clearable outlined dense label="Артикул 1С" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="activeFilters.nmid" clearable outlined dense label="Артикул" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                </v-row>
			</div>
		</div>
        <v-data-table
            :headers="table.header_table"
            :items="table.data"
            :loading="table.loading"
            class="elevation-0 small-font mt-3 vertical-border"
            hide-default-footer
            fixed-header
			height="calc(100vh - 220px)"
			v-model="table.selected_items"
            disable-pagination
			:item-class="itemClasses"
			:sort-by.sync="table.sortBy" 
			:sort-desc.sync="table.sortDesc"
            dense
			show-select
        >
			<template 
				v-for="prop in table.header_table"
				v-slot:[`header.${prop.value}`]="{ header }"
			>
				<div class="move_column_container" style="display: flex;" :key="prop.value" :class="`th_${prop.value}`" @contextmenu="showHeader($event, header.value)">
					<v-menu
						v-model="show_menu_header[prop.value]"
						:position-x="x_header"
						:position-y="y_header"
						absolute
						offset-y
					>
						<v-list>
							<v-list-item @click="moveColumnLeft($event, header.value, header.number)">
								<v-list-item-title style="font-size: .9em;">Переместить влево</v-list-item-title>
							</v-list-item>
							<v-list-item @click="moveColumnRight($event, header.value, header.number)"> 
								<v-list-item-title style="font-size: .9em;">Переместить вправо</v-list-item-title>
							</v-list-item>
							<v-list-item @click="hiddenColumn($event, header.value)">
								<v-list-item-title style="font-size: .9em;">Скрыть столбец</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
                    <div style="padding: 16px 0; word-break: break-all;">{{ header.text }}</div>
					<div class="vertical_line" @drag="drag" draggable @mousedown="startDrag" @dragend="saveWidth(header.value)"></div>				
                </div>
			</template>
			<template 
				v-for="prop2 in ['brand', 'status', 'group', 'account']"
				v-slot:[`header.${prop2}`]="{ header }"
			>
				<div class="move_column_container" style="display: flex;" :key="prop2" :class="`th_${prop2}`" @contextmenu="showHeader($event, header.value)">
					<v-menu
						v-model="show_menu_header[header.value]"
						:position-x="x_header"
						:position-y="y_header"
						absolute
						offset-y
					>
						<v-list>
							<v-list-item @click="moveColumnLeft($event, header.value, header.number)">
								<v-list-item-title style="font-size: .9em;">Переместить влево</v-list-item-title>
							</v-list-item>
							<v-list-item @click="moveColumnRight($event, header.value, header.number)"> 
								<v-list-item-title style="font-size: .9em;">Переместить вправо</v-list-item-title>
							</v-list-item>
							<v-list-item @click="hiddenColumn($event, header.value)">
								<v-list-item-title style="font-size: .9em;">Скрыть столбец</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
                    <div style="padding: 16px 0;">{{ header.text }}</div>
                    <div style="display: flex; align-items: center;">
                        <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" left fixed style="position: absolute; right: 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                                    <v-icon small 
                                    :color="activeFilters[header.value] && activeFilters[header.value].length < filters[header.value].length ? 'red' : 'default'"
                                    >mdi-filter-variant</v-icon>
                                </v-btn>
                            </template>
                            <v-list flat dense class="pa-0">
                                <v-list-item-group multiple v-model="activeFilters[header.value]" class="py-2">
                                    <template v-for="(item, i) in filters[header.value]">
                                        <v-list-item :key="`${i}`" :value="item" :ripple="false">
                                            <template v-slot:default="{ active, toggle }">
                                                <v-list-item-action>
                                                    <v-checkbox 
                                                        :input-value="active" 
                                                        :true-value="item"
                                                        @click="!toggle" 
                                                        color="primary" 
                                                        dense
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content> 
                                                    <v-list-item-title v-text="item" style="font-size: 12px"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-list-item-group>
                                <v-divider></v-divider>
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <v-btn 
                                            text 
                                            block 
                                            @click="toggleAll(header.value)" 
                                            color="success"
                                            style="font-size: 12px"
                                        >Toggle all</v-btn>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn 
                                            text 
                                            block
											@click="activeFilters[header.value] = []"
                                            color="warning"
                                            style="font-size: 12px"
                                        >Clear all</v-btn>
                                    </v-col>
                                </v-row>
                            </v-list>
                        </v-menu>
                    </div>
					<div class="vertical_line" @drag="drag" draggable @mousedown="startDrag" @dragend="saveWidth(header.value)"></div>				
                </div>
			</template>
			<template 
				v-for="prop in [
					'promotion_1', 'promotion_2', 'promotion_3', 'vp_promotion_1', 
					'vp_promotion_2', 'vp_promotion_3', 'rentab_promotion_1', 
					'rentab_promotion_2', 'rentab_promotion_3', 'cost_price'
				]"
				v-slot:[`header.${prop}`]="{ header }"
			>
				<div class="move_column_container" style="display: flex;" :key="prop" :class="`th_${prop}`" @contextmenu="showHeader($event, header.value)">
					<v-menu
						v-model="show_menu_header[header.value]"
						:position-x="x_header"
						:position-y="y_header"
						absolute
						offset-y
					>
						<v-list>
							<v-list-item @click="moveColumnLeft($event, header.value, header.number)">
								<v-list-item-title style="font-size: .9em;">Переместить влево</v-list-item-title>
							</v-list-item>
							<v-list-item @click="moveColumnRight($event, header.value, header.number)"> 
								<v-list-item-title style="font-size: .9em;">Переместить вправо</v-list-item-title>
							</v-list-item>
							<v-list-item @click="hiddenColumn($event, header.value)">
								<v-list-item-title style="font-size: .9em;">Скрыть столбец</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
                    <div style="padding: 16px 0;">{{ header.text }}</div>
                    <div style="display: flex; align-items: center;">
                        <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" left fixed style="position: absolute; right: 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                                    <v-icon small 
                                    :color="activeFilters[header.value] && activeFilters[header.value] > 0 ? 'red' : 'default'"
                                    >mdi-filter-variant</v-icon>
                                </v-btn>
                            </template>
                            <v-list flat dense class="pa-0">
								<v-radio-group
									v-model="activeFilters[header.value]"
									hide-details
									class="mt-1"
								>
									<div>
										<v-radio
											label="Все"
											:value=0
										></v-radio>
									</div>
									<div class="mt-3">
										<v-radio
											label="Заполнено"
											:value=1
										></v-radio>
									</div>
									<div class="mt-3">
										<v-radio
											label="Не заполнено"
											:value=2
										></v-radio>
									</div>
								</v-radio-group>
                            </v-list>
                        </v-menu>
                    </div>
					<div class="vertical_line" @drag="drag" draggable @mousedown="startDrag" @dragend="saveWidth(header.value)"></div>		
				</div>
			</template>
			<template v-slot:header.stocks_api="{ header }">
				<div class="move_column_container" :class="`th_${header.value}`" @contextmenu="showHeader($event, header.value)">
					<v-menu
						v-model="show_menu_header[header.value]"
						:position-x="x_header"
						:position-y="y_header"
						absolute
						offset-y
					>
						<v-list>
							<v-list-item @click="moveColumnLeft($event, header.value, header.number)">
								<v-list-item-title style="font-size: .9em;">Переместить влево</v-list-item-title>
							</v-list-item>
							<v-list-item @click="moveColumnRight($event, header.value, header.number)"> 
								<v-list-item-title style="font-size: .9em;">Переместить вправо</v-list-item-title>
							</v-list-item>
							<v-list-item @click="hiddenColumn($event, header.value)">
								<v-list-item-title style="font-size: .9em;">Скрыть столбец</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<div 
						@click="changeCustomSearch('stocks_api')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('stocks_api'),
							'active_search_desc': isCurrentSearchDesc('stocks_api')
						}]"
					>
						Остатки АПИ 
						<v-icon small>
							mdi-arrow-up
						</v-icon>
						
					</div>
					<hr>
					<div 
						@click="changeCustomSearch('stocks_mpstats')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('stocks_mpstats'),
							'active_search_desc': isCurrentSearchDesc('stocks_mpstats')
						}]"
					>
						Остатки МПСТАТС
						<v-icon small>
							mdi-arrow-up
						</v-icon>
					</div>
					<hr>
					<div 
						@click="changeCustomSearch('stocks_1c')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('stocks_1c'),
							'active_search_desc': isCurrentSearchDesc('stocks_1c')
						}]"
					>
						Остатки 1С
						<v-icon small>
							mdi-arrow-up
						</v-icon>
					</div>
				</div>
				<div class="vertical_line" @drag="drag" draggable @mousedown="startDrag" @dragend="saveWidth(header.value)"></div>		
			</template>
			<template v-slot:header.stock_day="{ header }">
				<div class="move_column_container" :class="`th_${header.value}`" @contextmenu="showHeader($event, header.value)">
					<v-menu
						v-model="show_menu_header[header.value]"
						:position-x="x_header"
						:position-y="y_header"
						absolute
						offset-y
					>
						<v-list>
							<v-list-item @click="moveColumnLeft($event, header.value, header.number)">
								<v-list-item-title style="font-size: .9em;">Переместить влево</v-list-item-title>
							</v-list-item>
							<v-list-item @click="moveColumnRight($event, header.value, header.number)"> 
								<v-list-item-title style="font-size: .9em;">Переместить вправо</v-list-item-title>
							</v-list-item>
							<v-list-item @click="hiddenColumn($event, header.value)">
								<v-list-item-title style="font-size: .9em;">Скрыть столбец</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<div 
						@click="changeCustomSearch('stock_day_mp')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('stock_day_mp'),
							'active_search_desc': isCurrentSearchDesc('stock_day_mp')
						}]"
					>
						Дней остаток МП
						<v-icon small>mdi-arrow-up</v-icon>
					</div>
					<hr>
					<div 
						@click="changeCustomSearch('stock_day_mp_1c')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('stock_day_mp_1c'),
							'active_search_desc': isCurrentSearchDesc('stock_day_mp_1c')
						}]"
					>
						Дней остаток МП + 1С
						<v-icon small>mdi-arrow-up</v-icon>
					</div>
				</div>
				<div class="vertical_line" @drag="drag" draggable @mousedown="startDrag" @dragend="saveWidth(header.value)"></div>		
			</template>
			<template v-slot:header.speed_orders="{ header }">
				<div class="move_column_container" :class="`th_${header.value}`" @contextmenu="showHeader($event, header.value)">
					<v-menu
						v-model="show_menu_header[header.value]"
						:position-x="x_header"
						:position-y="y_header"
						absolute
						offset-y
					>
						<v-list>
							<v-list-item @click="moveColumnLeft($event, header.value, header.number)">
								<v-list-item-title style="font-size: .9em;">Переместить влево</v-list-item-title>
							</v-list-item>
							<v-list-item @click="moveColumnRight($event, header.value, header.number)"> 
								<v-list-item-title style="font-size: .9em;">Переместить вправо</v-list-item-title>
							</v-list-item>
							<v-list-item @click="hiddenColumn($event, header.value)">
								<v-list-item-title style="font-size: .9em;">Скрыть столбец</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<div 
						@click="changeCustomSearch('speed_orders_14')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('speed_orders_14'),
							'active_search_desc': isCurrentSearchDesc('speed_orders_14')
						}]"
					>
						Скорость заказов 14 
						<v-icon small>mdi-arrow-up</v-icon>
					</div>
					<hr>
					<div 
						@click="changeCustomSearch('speed_orders_7')"
						:class="['custom_search', {
							'active_search_by': isCurrentSearchBy('speed_orders_7'),
							'active_search_desc': isCurrentSearchDesc('speed_orders_7')
						}]"
					>
						Скорость заказов 7
						<v-icon small>mdi-arrow-up</v-icon>
					</div>
				</div>
				<div class="vertical_line" @drag="drag" draggable @mousedown="startDrag" @dragend="saveWidth(header.value)"></div>		
			</template>
			<template v-slot:item.nmid="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'nmid', item.id)" 
					:class="['td_custom', `cell_${'nmid'}_${item.id}`]"
					:id="`cell_${'nmid'}_${item.id}`"
				>
					<router-link 
						target="_blank" 
						:to="{ name: 'product_card', params: { id: item.nmid }}"
					>{{ item.nmid }}</router-link>
				</div>
	
			</template>
			<template v-slot:item.stocks_api="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'stock_api', item.id)" 
					:class="['td_custom', `cell_${'stock_api'}_${item.id}`]"
					:id="`cell_${'stock_api'}_${item.id}`"
				>
					{{item.stocks_api ? item.stocks_api.toLocaleString() : 0}}
					<hr>
					{{item.stocks_mpstats ? item.stocks_mpstats.toLocaleString() : 0}}
					<hr>
					{{item.stocks_1c ? item.stocks_1c.toLocaleString() : 0}}
				</div>
			</template>	
			<template v-slot:item.stock_day="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'stock_day', item.id)" 
					:class="['td_custom', `cell_${'stock_day'}_${item.id}`]"
					:id="`cell_${'stock_day'}_${item.id}`"
				>
					{{item.stock_day_mp ? item.stock_day_mp.toLocaleString() : 0}}
					<hr>
					{{item.stock_day_mp_1c ? item.stock_day_mp_1c.toLocaleString() : 0}}
				</div>
			</template>	
			<template v-slot:item.speed_orders="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'speed_orders', item.id)" 
					:class="['td_custom', `cell_${'speed_orders'}_${item.id}`]"
					:id="`cell_${'speed_orders'}_${item.id}`"
				>
					{{item.speed_orders_14 ? item.speed_orders_14.toFixed(2) : 0}}
					<hr>
					{{item.speed_orders_7 ? item.speed_orders_7.toFixed(2) : 0}}
				</div>
			</template>	
			<template 
				v-for="prop3 in [
					'price_api_cost', 'price_api_sales', 'cost_price', 'calc_commission',
					'logistic', 'percent_spp', 'tax', 'redemption_percent', 'acquiring'
				]"
				v-slot:[`item.${prop3}`]="{ item }"
			>
				<div 
					@contextmenu="showColorPickerRow($event, prop3, item.id)" 
					:class="['td_custom', `cell_${prop3}_${item.id}`]"
					:key="`context_${prop3}`"
					:id="`cell_${prop3}_${item.id}`"
				>
					<div 
						style="min-width: 75px; width: 100%; height: 100%; background-color: inherit;" 
						:class="{
							'green_el': item.select && (prop3 == 'price_api_sales' || prop3 == 'price_api_cost')
						}"
						:id="`editing_field_${prop3}_${item.id}`"
					>
						<div class="test" v-if="`editing_field_${prop3}_${item.id}` != editing_input" @dblclick="editing_input = `editing_field_${prop3}_${item.id}`">
							{{ item[prop3] ? Number(item[prop3]).toLocaleString(): ''}}
						</div>
						<input 
							class="test" 
							style="border: 1px solid black;" 
							v-else type="number" 
							:value="item[prop3]" 
							@change="EditData(`editing_field_${prop3}_${item.id}`, item, prop3, $event)">
					</div>
				</div>

			</template>
			<template 
				v-for="prop4 in [
					'vp_promotion_1', 'vp_promotion_2', 'vp_promotion_3',
					'rentab_promotion_1', 'rentab_promotion_2', 'rentab_promotion_3', 
				]"
				v-slot:[`item.${prop4}`]="{ item }"
			>
				<div 
					@contextmenu="showColorPickerRow($event, prop4, item.id)" 
					:class="['td_custom', `cell_${prop4}_${item.id}`]"
					:key="`context_${prop4}`"
					:id="`cell_${prop4}_${item.id}`"
				>
					<div 
						style="width: 100%; 
						height: 100%;" 
						class="calc_test"
						:class="{'green_el': isSelect(prop4, item)}"
						@dblclick="selectPromotion(item, prop4)"
						v-if="item[prop4]"
					>
						{{item[prop4] ? item[prop4].toLocaleString(): 0}}
					</div>
				</div>
			</template>
			<template 
				v-for="prop6 in ['promotion_1', 'promotion_2', 'promotion_3']"
				v-slot:[`item.${prop6}`]="{ item }"
			>
				<div 
					@contextmenu="showColorPickerRow($event, prop6, item.id)" 
					:class="['td_custom', `cell_${prop6}_${item.id}`, {'check_in': checkInPromotion(prop6, item)}]"
					:id="`cell_${prop6}_${item.id}`"
					:key="`context_${prop6}`"
				>
					{{ item[prop6] ? item[prop6] : '' }}
				</div>				
			</template>
			<template v-slot:item.action="{ item }">
				<v-icon small @click="refreshItem(item)">
					mdi-reload
				</v-icon>
			</template>	
			<template v-slot:item.photo="{ item }">
				<div class="image">
					<img style="max-height:50px" :src="item.image" alt="">
					<img class="big_img" :src="item.image" alt="">
				</div>
			</template>
			<template v-slot:item.catalog_item.code1C_name="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'catalog_item.code1C_name', item.id)" 
					:class="['td_custom', `cell_${'catalog_item.code1C_name'}_${item.id}`]"
					:id="`cell_${'catalog_item.code1C_name'}_${item.id}`"
				>
					{{ item.catalog_item ? item.catalog_item.code1C_name : '' }}
					<v-icon @click="openCompetitors(item)" style="cursor: pointer;" small>mdi-account-badge</v-icon>
				</div>
				
			</template>
			<template v-slot:item.catalog_item.code1C_code="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'catalog_item.code1C_code', item.id)" 
					:class="['td_custom', `cell_${'catalog_item.code1C_code'}_${item.id}`]"
					:id="`cell_${'catalog_item.code1C_code'}_${item.id}`"
				>
					{{ item.catalog_item ? item.catalog_item.code1C_code : '' }}
				</div>
				
			</template>
			<template 
				v-for="prop5 in [
					'brand', 'status', 'group', 'account', 'spp', 'vp', 'rentab', 'final_price', 'markup', 
				]"
				v-slot:[`item.${prop5}`]="{ item }"
			>
				<div 
					@contextmenu="showColorPickerRow($event, prop5, item.id)" 
					:class="['td_custom', `cell_${prop5}_${item.id}`]"
					:key="`context_${prop5}`"
					:id="`cell_${prop5}_${item.id}`"
					v-if="prop5 != 'brand' && prop5 != 'status' && prop5 != 'group' && prop5 != 'account'"
				>
					{{Number(item[prop5]).toLocaleString()}}
					<v-icon small @click="addToFixFinalPrice(item)" v-if="prop5 == 'final_price' && item[prop5]" :key="`add_${prop5}_${item.id}`">mdi-plus-circle-outline</v-icon>
				</div>
				<div 
					v-else
					@contextmenu="showColorPickerRow($event, prop5, item.id)" 
					:class="['td_custom', `cell_${prop5}_${item.id}`]"
					:key="`context2_${prop5}`"
					:id="`cell_${prop5}_${item.id}`"
				>
					{{ item[prop5] }}
				</div>
			</template>
			<template v-slot:item.fix_final_price="{ item }">
				<div 
					@contextmenu="showColorPickerRow($event, 'fix_final_price', item.id)" 
					:class="['td_custom', `cell_${'fix_final_price'}_${item.id}`]"
					:id="`cell_${'fix_final_price'}_${item.id}`"
				>
					<div 
						style="width: 100%; height: 100%;" class="calc_test" 
						@dblclick="returnFinalPriceFromFix(item)"
						v-if="item.fix_final_price && item.fix_final_price > 0"
					>
						{{item.fix_final_price ? item.fix_final_price.toLocaleString(): 0}}
					</div>
				</div>
			</template>
        </v-data-table>
        <div class="mt-5" style="width: 100%; display: flex; justify-content: space-between;">
            <div>
				<div v-if="!general_data.url_export_table">
                    <v-text-field 
                        v-model="email_google" clearable outlined dense hide-details
                        label="Введите email перед созданием таблицы" v-if="general_data.is_owner"
                    ></v-text-field>
                    <div class="mt-3" v-if="!general_data.url_export_table && general_data.name">
                        <v-btn 
                            depressed color="blue darken-2" style="font-size: .9em;" :loading="loading_export_google"
                            dark @click="exportToGoogleSheet(false)" v-if="general_data.is_owner"
                        >Сформировать google sheet</v-btn>
                        <v-alert density="compact" type="warning" v-else>
                            У вас нет прав для создания google таблицы, обратитесь к владельцу таблицы
                        </v-alert>
                    </div>
                </div>
				<div v-else>
                    <div class="mt-3" v-if="general_data.url_export_table">
                        <v-btn depressed color="orange darken-2" style="font-size: .9em;" dark>
                            <a :href="general_data.url_export_table" target="_blank">Открыть google sheet</a>
                        </v-btn>
                    </div>
                    <div class="mt-3" v-if="general_data.url_export_table">
                        <v-btn 
                            depressed color="green darken-2" style="font-size: .9em;" :loading="loading_export_google"
                            dark @click="exportToGoogleSheet(false)"
                        >Обновить данные в google sheet</v-btn>
                    </div>
                    <div class="mt-3" v-if="general_data.url_export_table && general_data.is_owner">
                        <v-btn 
                            depressed color="red darken-2" style="font-size: .9em;" :loading="loading_export_google"
                            dark @click="exportToGoogleSheet(true)"
                        >Удалить google sheet</v-btn>
                    </div>
                </div>
			</div>
			<v-btn
				depressed
				color="blue darken-2"
				style="font-size: .9em;"
				dark
				@click="openDialogActions"
			>Управление акциями</v-btn>
			<v-btn
				depressed
				color="blue darken-2 "
				style="font-size: .9em;"
				dark
				@click="openDialogPreUpdate"
				v-if="table.selected_items.length > 0"
			>Установить цену ({{table.selected_items.length}})</v-btn>

			<v-btn
				depressed
				color="blue darken-2"
				style="font-size: .9em;"
				dark
				@click="cleanTemplateState"
			>Обновить данные</v-btn>
			<v-btn
				depressed
				color="blue darken-2"
				style="font-size: .9em;"
				dark
				@click="$router.push({name: 'change_management_product_template', params: {id: templateId}})"
			>Изменить шаблон</v-btn>
			<v-btn
				depressed
				color="blue darken-2"
				style="font-size: .9em;"
				dark
				@click="editing_data.dialog_editing_data=true"
				v-if="table.selected_items.length > 0"
			>Массовое редактирование({{ table.selected_items.length }})</v-btn>
			<div style="display: flex; flex-direction: column;">
				<v-btn
					depressed
					color="blue darken-2"
					style="font-size: .9em;"
					dark
					@click="cleanLocalStorage"
				>Сбросить ширину</v-btn>
				<v-btn
					depressed
					color="blue darken-2"
					class="mt-3"
					style="font-size: .9em;"
					dark
					@click="cleanLocalStorageHidden"
				>Открыть скрытые колонки</v-btn>
				<v-btn
					depressed
					color="blue darken-2"
					class="mt-3"
					style="font-size: .9em;"
					dark
					@click="cleanLocalStorageOrder"
				>Восстановить порядок</v-btn>
				<v-btn
					depressed
					color="blue darken-2"
					class="mt-3"
					style="font-size: .9em;"
					dark
					@click="cleanLocalStorageCell"
				>Сбросить настройку цветов</v-btn>
			</div>
			
        </div>  
    </v-container>
    
</template>
  
<script>
	import { ManagementProduct } from '@/api/management_product'
	import Vue from "vue";
	import JsonExcel from "vue-json-excel";
	Vue.component("downloadExcel", JsonExcel);
	import CompetitorsPriceChart from '@/components/Product/CompetitorsPriceChart';

	export default {
		name: 'ManagementWbProductMain',
		data() {
			return {
				loading_export_google: false,
				email_google: null,
				dialog_alert: false,
				exists_promotion: {
					dialog: false,
					loading: false,
					number: null,
					data: [],
					loading_add: false,
					headers: [
						{ text: 'Название', sortable: true, value: 'title'},
						{ text: 'Период', sortable: true, value: 'date_from'},
						{ text: 'Если зашли в акцию', sortable: false, value: 'is_entered_commission'},
						{ text: 'Если не зашли в акцию', value: 'is_not_entered_commission', sortable: false},
						{ text: 'Минимальная комиссия', value: 'min_commission', sortable: false},
						{ text: 'Товаров в акции', value: 'cnt_in_products', sortable: false},
						{ text: 'Товаров вне акции', value: 'cnt_out_products', sortable: false},
						{ text: 'Актуальная акция', value: 'is_actual', sortable: false},
						{ text: 'Добавить', value: 'action', sortable: false},
					]
				},
				copy_promotion_loading: false,
				promotion_update: {
					dialog: false,
					loading: false,
					promotion: null
				},
				search_promotion: {
					dialog: false,
					number: null,
					search: null,
					loading: null,
					loading_set: null,
					headers: [
						{ text: 'Аккаунт', sortable: true, value: 'account'},
						{ text: 'Название', sortable: true, value: 'name'},
						{ text: 'Шаблон', sortable: true, value: 'template'},
						{ text: 'Если зашли в акцию', sortable: false, value: 'is_entered_to_promotion'},
						{ text: 'Если не зашли в акцию', value: 'is_not_entered_to_promotion', sortable: false},
						{ text: 'Минимальная комиссия', value: 'minimum_commission', sortable: false},
						{ text: 'выбор', value: 'action', sortable: false},
					],
					data: []
				},
				search_promotion_dialog: false,
				search_promotion_number: null,
				list_actions: [],
				editing_input: null,
				cur_test: null,
				formatting_input: false,
				show_color_picker_row: false,
				color_row: '#c8e6c9',
				selectCell: null,
				id_select: null,
				color: '#c8e6c9',
				show_color_picker_cell: false,
				show_menu_header: {
					'nmid': false, 'stocks_api': false, 'stock_day': false, 'speed_orders': false, 'price_api_cost': false, 'photo': false,
					'price_api_sales': false, 'cost_price': false, 'calc_commission': false, 'logistic': false,
					'percent_spp': false, 'tax': false, 'redemption_percent': false,
					'vp_promotion_1': false, 'vp_promotion_2': false, 'vp_promotion_3': false, 'rentab_promotion_1': false,
					'rentab_promotion_2': false, 'rentab_promotion_3': false, 'catalog_item.code1C_name': false,
					'promotion_1': false, 'promotion_2': false, 'promotion_3': false,
					'brand': false, 'status': false, 'group': false, 'account': false, 'spp': false, 'vp': false, 'rentab': false,
					'catalog_item.code1C_code': false, 'final_price': false, 'fix_final_price': false, 'markup': false,
					'acquiring': false
				},
				x: 0,
				y: 0,
				y_header: 0,
				x_header: 0,
				dialog_competitors: false,
				selected_item_competitors: null,
				competitors_data: [],
				competitors_header: [
					{ text: 'Изображение', sortable: false, value: 'photo', width: '15%'},
					{ text: 'Артикул', sortable: false, value: 'nmid', width: '15%'},
					{ text: 'Текущие цена/спп', sortable: false, value: 'last_data', width: '15%'},
					{ text: 'Цена', sortable: false, value: 'chart_price', width: '55%', align: 'center'},
				],
				dragCord: {
					start_x: 0,
					cur_width: 0
				},
				editing_data: {
					dialog_editing_data: false,
					edition_dict: [
						{"id": "price_api_cost", "text": "Цена до скидки"},
						{"id": "price_api_sales", "text": "Согласованная скидка"},
						{"id": "percent_spp", "text": "СПП в %"},
						{"id": "cost_price", "text": "С/С"},
						{"id": "logistic", "text": "Логистики, руб"},
						{"id": "commission", "text": "Комиссия, %"},
						{"id": "redemption_percent", "text": "% выкупа"},
						{"id": "tax", "text": "Налог, %"},
						{"id": "acquiring", "text": "Эквайринг"}
					],
					new_value: null,
					select_type: null
				},
				update_price_discount: {
					checked: false,
					dialog_pre_update_price_discount: false,
					pre_update_items: [],
                    loading_check: false,
					date_update: null,
					time_update: null
				},
				datePicker: {
					datepicker_date: "",
					datepicker_time: ""
				},
				promotion: {
					dialog: false,
					list_wbaccount: [],
					choices_account: [],
					data: [],
					filter_data: [],
					first_promotion: null,
					second_promotion: null,
					third_promotion: null
				},
				promotion_create: {
					dialog: false,
					number: null,
					form: {
						name: null,
						is_entered_to_promotion: null,
						is_not_entered_to_promotion: null,
						minimum_commission: null,
					},
					loading: false
				},
				saving_flag: false,
				general_data: {},
				filters: { 
					'brand': [], 
					'group': [], 
					'status': [],
					'account': [],
					'vp_promotion_1': 0,
					'vp_promotion_2': 0,
					'vp_promotion_3': 0,
					'promotion_1': 0,
					'promotion_2': 0,
					'promotion_3': 0,
					'rentab_promotion_1': 0,
					'rentab_promotion_2': 0,
					'rentab_promotion_3': 0,
					'cost_price': 0
				},
				activeFilters: {},
				table: {
					loading: false,
					data: [],
					fresh_data: [],
					sortBy: [],
					sortDesc: [],
					selected_items: [],
					header_table: []
				},
				json_fields: {
					"Наименование": "catalog_item.code1C_name",
					"Бренд": "brand",
					"Статус": "status",
					"Группа товаров": "group",
					"ЛК": 'account',
					"Артикул поставщика": "nmid",
					"Номенклатура (код 1С)": "catalog_item.code1C_code",
					"Новая розн. цена (до скидки)": "price_api_cost",
					"Согласованная скидка, %": "price_api_sales",
					"Цена итоговая": "final_price",
					"Акция 1": "promotion_1",
					"Акция 2": "promotion_2",
					"Акция 3": "promotion_3",
					"СПП в %": "percent_spp",
					"Цена с СПП": "spp",
					"с/с": "cost_price",
					"комиссия, %": "commission",
					"логистика, руб": "logistic",
					"% выкупа": "redemption_percent",
					"Налог в %": "tax",
					"ВП, руб": "vp",
					"ВП 1": "vp_promotion_1",
					"ВП 2": "vp_promotion_2",
					"ВП 3": "vp_promotion_3",
					"Наценка, %": "markup",
					"Рентабельность, %": "rentab",
					"Рентабельность 1": "rentab_promotion_1",
					"Рентабельность 2": "rentab_promotion_2",
					"Рентабельность 3": "rentab_promotion_3",
					"Остатки АПИ": "stocks_api",
					"Остатки МПСТАТС": "stocks_1c",
					"Остатки 1С": "stocks_mpstats",
				},
				old_data_fix_price: null
			}
		},
		components: {CompetitorsPriceChart},
		watch: {
			'table.data': async function (newVal, oldVal) {
				if (oldVal != newVal) {
					console.log("Сохранение state")
					await this.saveTemplateState()
				}
			},
			'promotion.choices_account': async function (newVal, oldVal) {
				if (newVal && oldVal != newVal) {
					console.log("Аккаунт поменялся")
					this.promotionsByNumber()
				}
			},
		},
		computed: {
			dateFormatted () {
				return this.update_price_discount.date_update ? this.$options.filters.formatDateToLocaleNoTime(this.update_price_discount.date_update) : null
			},
			timeFormatted () {
				return this.update_price_discount.time_update ? this.update_price_discount.time_update : null
			},
            allCheckedSuccessfully(){
				this.update_price_discount.pre_update_items.forEach(item => {
					if (!('checked' in item) || !item?.checked){
						return false
					}
				})
				return true
			},
			templateId(){
				return this.$route.params.template_id;
			},
            transformProductsForRequest(){
                let array = []
                this.update_price_discount.pre_update_items.forEach(item => {
                    array.push({
                        "nmid": item.nmid,
                        "price": item.price_api_cost,
                        "discount": item.price_api_sales,
                    })
                })
                return array
            }
		},
		methods: {	
			returnFinalPriceFromFix(item){
				let index = this.table.data.indexOf(item)
				this.table.data[index].price_api_sales = item.fix_base_sale
				this.table.data[index].price_api_cost = item.fix_price
				this.table.data[index]['select'] = false
				this.table.data[index]['select_1'] = false
				this.table.data[index]['select_2'] = false
				this.table.data[index]['select_3'] = false
				this.calculateValue()
			},
			addToFixFinalPrice(item){
				let index = this.table.data.indexOf(item)
				this.table.data[index].fix_final_price = item.final_price
				this.table.data[index].fix_base_sale = item.price_api_sales
				this.table.data[index].fix_price = item.price_api_cost
				this.calculateValue()
			},
			async addExistPromotionToTemplate(prom_id){
				this.exists_promotion.loading_add = true
				let json_data = {
					"template": this.templateId, "account": this.promotion.choices_account,
					"number": this.exists_promotion.number, "promotion": prom_id,
				}
				try{
					await ManagementProduct.addExistPromotionToTemplate(json_data)
					await this.fetchProductData()
					this.promotionsByNumber()
				}catch(err){
					console.log(err)
				}
				this.exists_promotion.loading_add = false
				this.exists_promotion.dialog = false
			},
			async openExistsPromotions(number){
				this.exists_promotion.data = []
				this.exists_promotion.dialog = true
				this.exists_promotion.number = number
				this.exists_promotion.loading = true
				try{
					const response = await ManagementProduct.fetchExistPromotion()
					this.exists_promotion.data = response.data
				} catch(err){
					console.log(err)
				}
				this.exists_promotion.loading = false
				
			},
			async searchPromotion(){
				this.search_promotion.loading = true
				try{
					const response = await ManagementProduct.SearchPromotion(this.search_promotion.search)
					this.search_promotion.data = response.data
				}catch(err){
					console.log(err)
				}
				this.search_promotion.loading = false
			},
			async selectPromotionFromOutside(prom_id){
				this.search_promotion.loading_set = true
				let json_data = {
					"template": this.templateId, "account": this.promotion.choices_account,
					"number": this.search_promotion.number, "promotion": prom_id,
				}
				try{
					await ManagementProduct.selectPromotionFromOutside(json_data)
					await this.fetchProductData()
					this.promotionsByNumber()
				}catch(err){
					console.log(err)
				}
				this.search_promotion.loading_set = false
				this.search_promotion.dialog = false
			},
			checkInPromotion(head, item){
				if (item[head] && item.final_price){
					if (item.final_price < item[head])
						return true
					return false
				}
				return false
			},
			EditData(id, item, prop3, event){
				let index = this.table.data.indexOf(item)
				prop3 = prop3 != 'calc_commission' ? prop3 : 'commission'
				let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_editing_field"))
				cur_json = cur_json ? cur_json : {}
				this.list_actions.push({
					'type': 'change_simple_field',
					'old_value': item[prop3],
					'new_value': event.target.value,
					'field': prop3,
					'id_cell': id,
					'real_id': item.id,
					'new_color': cur_json[id] == "#d9c9ad" ? false : true
				})
				this.table.data[index][prop3] = event.target.value
				this.editing_input = null
				this.calculateValue()
				this.addEditingStyle(id)
				
			},
			addEditingStyle(id){
				try{
					let id_select = id
					if (localStorage.getItem("lomex_management_color_editing_field")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_editing_field"))
						cur_json[id_select] = "#d9c9ad"
						localStorage.setItem('lomex_management_color_editing_field', JSON.stringify(cur_json));
					}
					else{
						let js = {}
						js[id_select] = "#d9c9ad"
						localStorage.setItem('lomex_management_color_editing_field', JSON.stringify(js));
					}
					let el = document.getElementById(id_select)
					if (el)
						el.style.backgroundColor = '#d9c9ad'
				} catch (err){
					console.log(err)
				}
			},
			initColorEditingFiedl(){
				if (localStorage.getItem("lomex_management_color_editing_field")){
					let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_editing_field"))
					for (let [key, value] of Object.entries(cur_json)){
						try{
							let cell = document.getElementById(key)
							if (cell){
								cell.style.backgroundColor = value
							}
						} catch (err){
							console.log("Ошибка")
						}
						
					}
				}
			},
			resetColorCell(){
				try{
					let id_select = this.id_select
					if (localStorage.getItem("lomex_management_color_cell")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_cell"))
						this.list_actions.push({
							'type': 'change_color_cell',
							'old_value': cur_json[id_select] ? cur_json[id_select]: null,
							'new_value': null,
							'id_cell': id_select,
						})
						delete cur_json[id_select]
						localStorage.setItem('lomex_management_color_cell', JSON.stringify(cur_json));
					}
					let el = document.getElementById(id_select)
					if (el)
						el.style.backgroundColor = 'inherit'
				} catch(err){
					console.log(err)
				}
			},
			setColor(){
				try{
					let id_select = this.id_select
					if (localStorage.getItem("lomex_management_color_cell")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_cell"))
						this.list_actions.push({
							'type': 'change_color_cell',
							'old_value': cur_json[id_select] ? cur_json[id_select] : null,
							'new_value': this.color,
							'id_cell': id_select,
						})
						cur_json[id_select] = this.color
						localStorage.setItem('lomex_management_color_cell', JSON.stringify(cur_json));
					}
					else{
						this.list_actions.push({
							'type': 'change_color_cell',
							'old_value': null,
							'new_value': this.color,
							'id_cell': id_select,
						})
						let js = {}
						js[id_select] = this.color
						localStorage.setItem('lomex_management_color_cell', JSON.stringify(js));
					}
					let el = document.getElementById(id_select)
					if (el)
						el.style.backgroundColor = this.color
				} catch (err){
					console.log(err)
				}
				this.color = '#c8e6c9'
			},
			initColorCell(){
				if (localStorage.getItem("lomex_management_color_cell")){
					let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_cell"))
					for (let [key, value] of Object.entries(cur_json)){
						try{
							let cell = document.getElementById(key)
							if (cell){
								cell.style.backgroundColor = value
							}
						} catch (err){
							console.log("Ошибка")
						}
						
					}
				}
			},
			openRowFillColorPicker(e){
				this.x = e.clientX
				this.y = e.clientY
				this.show_color_picker_row = true
			},
			showColorPickerRow(e, name, id){
				e.stopPropagation()
				e.preventDefault()
				this.cur_test = `${name}_${id}`
				this.id_select = `cell_${name}_${id}`
				this.selectCell = e.target
				this.x = e.clientX
				this.y = e.clientY
				this.show_color_picker_cell = true
			},
			showHeader(e, name){
				e.stopPropagation()
				e.preventDefault()
				this.show_menu_header[name] = false
				this.x_header = e.clientX
				this.y_header = e.clientY
				this.show_menu_header[name] = true
			},
			setColorToRow(){
				try{
					let id_rows = []
					this.table.selected_items.forEach(item => {
						id_rows.push(item.id)
					})
					id_rows.forEach(id_row => {
						let id_el = `color_row_${id_row}`
						if (localStorage.getItem("lomex_management_color_row")){
							let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_row"))
							cur_json[id_el] = this.color_row
							localStorage.setItem('lomex_management_color_row', JSON.stringify(cur_json));
						}
						else{
							let js = {}
							js[id_el] = this.color_row
							localStorage.setItem('lomex_management_color_row', JSON.stringify(js));
						}	
						try{
							var c = document.getElementsByClassName(id_el)[0].childNodes;
							c.forEach(el =>{
								el.style.backgroundColor = this.color_row
							})
						} catch (err){
							console.log("Ошибка")
						}
						this.color_row = '#c8e6c9'
					})
				} catch (err){
					console.log(err)
				}
			},
			initColorRow(){
				if (localStorage.getItem("lomex_management_color_row")){
					let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_row"))
					for (let [key, value] of Object.entries(cur_json)){
						try{
							var c = document.getElementsByClassName(key)[0].childNodes;
							c.forEach(el =>{
								el.style.backgroundColor = value
							})
						} catch (err){
							console.log("Ошибка")
						}
						
					}
				}
			},
			async openCompetitors(item){
				this.selected_item_competitors = item.nmid
				this.dialog_competitors = true
				await this.fetchCompetitorsPrice(item.nmid)
			},
			async fetchCompetitorsPrice(nmid){
				try{
					const resp = await ManagementProduct.getCompetitorsPrice(nmid)
					this.competitors_data = resp.data
				} catch (err){
					this.competitors_data = []
					console.log(err)
				}
			},
			calculatedHeaderWithPromotions(){
				let first_part_header = [
					{ text: 'Изображение', sortable: false, value: 'photo', number: 1, index: 0, align: 'center'},
					{ text: 'Наименование', sortable: true, value: 'catalog_item.code1C_name', number: 2, index: 1, align: 'center'},
					{ text: 'Бренд', sortable: false, value: 'brand', number: 3, index: 2, align: 'center', filter: value => this.filterInArray(this.activeFilters?.brand, value) },
					{ text: "Статус", sortable: false, value: 'status', number: 4, index: 3, align: 'center', filter: value => this.filterInArray(this.activeFilters.status, value) },
					{ text: 'Группа товаров', sortable: false, value: 'group', number: 5, index: 4, align: 'center', filter: value => this.filterInArray(this.activeFilters.group, value) },
					{ text: 'ЛК', sortable: false, value: 'account', number: 6, index: 5, align: 'center', filter: value => this.filterInArray(this.activeFilters.account, value) },
					{ text: 'Артикул поставщика', sortable: true, value: 'nmid', number: 7, index: 6, align: 'center', filter: value => this.filterSearchText(this.activeFilters?.nmid, value)},
					{ text: 'Номенклатура (код 1С)', sortable: true, value: 'catalog_item.code1C_code', number: 8, index: 7, align: 'center', filter: value => this.filterSearchText(this.activeFilters?.code_1c, value)},
					{ text: 'Новая розн. цена (до скидки)', sortable: true, value: 'price_api_cost', number: 9, index: 8, align: 'center'},
					{ text: 'Согласованная скидка, %', sortable: true, value: 'price_api_sales', number: 10, index: 9, align: 'center'},
					{ text: 'Цена итоговая', sortable: true, value: 'final_price', number: 11, index: 10, align: 'center'},
					{ text: 'Фикс. цена', sortable: false, value: 'fix_final_price', number: 12, index: 11, align: 'center'},
				]		
				let second_part_header = [
					{ text: 'СПП в %', sortable: true, value: 'percent_spp', number: 16, index: 15, align: 'center'},
					{ text: 'Цена с СПП', sortable: true, value: 'spp', number: 17, index: 16, align: 'center'},
					{ text: 'с/с', sortable: true, value: 'cost_price', number: 18, align: 'center', index: 17,filter: value => this.filterHasOrNot(this.activeFilters.cost_price, value)},
					{ text: 'комиссия, %', sortable: true, value: 'calc_commission', number: 19, index: 18, align: 'center'},
					{ text: 'логистика, руб', sortable: true, value: 'logistic', number: 20, index: 19, align: 'center'},
					{ text: '% выкупа', sortable: true, value: 'redemption_percent', number: 21, index: 20, align: 'center'},
					{ text: 'Налог в %', sortable: true, value: 'tax', number: 22, index: 21, align: 'center'},
					{ text: 'Эквайринг', sortable: true, value: 'acquiring', number: 23, index: 22, align: 'center'},
					{ text: 'ВП, руб', sortable: true, value: 'vp', number: 24, index: 23, align: 'center'},
				]
				let third_part_header = [
					{ text: 'Наценка, %', sortable: true, value: 'markup', number: 28, index: 27, align: 'center'},
					{ text: 'Рентабельность, %', sortable: true, value: 'rentab', number: 29, index: 28, align: 'center'},
				]	
				if (this.promotion.data.filter(item => item.number == 1).length > 0){
					let name = ''
					this.promotion.data.filter(item => item.number == 1).forEach(i=>{
						let text_prom = `${i.name} ${i.is_completed ? '(акция не действует)': ''}`
						name += name == '' ? `${text_prom}` : ` | ${text_prom}`
					})
					second_part_header.push(
						{ text: 'ВП 1', sortable: true, value: 'vp_promotion_1', number: 25, align: 'center', index: 24,filter: value => this.filterHasOrNot(this.activeFilters.vp_promotion_1, value) },
					)
					first_part_header.push(
						{ text: name, sortable: true, value: 'promotion_1', number: 13, align: 'center', index: 12, filter: value => this.filterHasOrNot(this.activeFilters.promotion_1, value)},
					)
					third_part_header.push(
						{ text: 'Рентабельность 1', sortable: true, value: 'rentab_promotion_1', number: 30, align: 'center', index: 29,filter: value => this.filterHasOrNot(this.activeFilters.rentab_promotion_1, value)},
					)
				}			
				if (this.promotion.data.filter(item => item.number == 2).length > 0){
					let name = ''
					this.promotion.data.filter(item => item.number == 2).forEach(i=>{
						let text_prom = `${i.name} ${i.is_completed ? '(акция не действует)': ''}`
						name += name == '' ? `${text_prom}` : ` | ${text_prom}`
					})
					second_part_header.push(
						{ text: 'ВП 2', sortable: true, value: 'vp_promotion_2', number: 26, align: 'center', index: 25,filter: value => this.filterHasOrNot(this.activeFilters.vp_promotion_2, value)},
					)
					first_part_header.push(
						{ text: name, sortable: true, value: 'promotion_2', number: 14, align: 'center', index: 13,filter: value => this.filterHasOrNot(this.activeFilters.promotion_2, value)},
					)
					third_part_header.push(
						{ text: 'Рентабельность 2', sortable: true, value: 'rentab_promotion_2', number: 31, align: 'center', index: 30,filter: value => this.filterHasOrNot(this.activeFilters.rentab_promotion_2, value)},
					)
				}				
				if (this.promotion.data.filter(item => item.number == 3).length > 0){
					let name = ''
					this.promotion.data.filter(item => item.number == 3).forEach(i=>{
						let text_prom = `${i.name} ${i.is_completed ? '(акция не действует)': ''}`
						name += name == '' ? `${text_prom}` : ` | ${text_prom}`
					})
					second_part_header.push(
						{ text: 'ВП 3', sortable: true, value: 'vp_promotion_3', number: 27, align: 'center', index: 26,filter: value => this.filterHasOrNot(this.activeFilters.vp_promotion_3, value)},
					)
					first_part_header.push(
						{ text: name, sortable: true, value: 'promotion_3', number: 15, align: 'center', index: 14,filter: value => this.filterHasOrNot(this.activeFilters.promotion_3, value)},
					)
					third_part_header.push(
						{ text: 'Рентабельность 3', sortable: true, value: 'rentab_promotion_3', number: 32, align: 'center', index: 31,filter: value => this.filterHasOrNot(this.activeFilters.rentab_promotion_3, value)},
					)
				}
				third_part_header.push(
					{ text: 'Остатки ВБ (ЛК / МПСТАТС/ 1С)', sortable: false, value: 'stocks_api', number: 33, index: 32, align: 'center' },
					{ text: 'Скорость заказов', sortable: false, value: 'speed_orders', number: 34, index: 33, align: 'center' },
					{ text: 'Дней остатка (Остатки МП /Остатки МП+1С)', sortable: false, value: 'stock_day', number: 35, index: 34, align: 'center'},
					{ text: 'Обновить', sortable: false, value: 'action', number: 36, index: 34, align: 'center' },
				)		
				let result = [...first_part_header, ...second_part_header, ...third_part_header]
				let order_column = JSON.parse(localStorage.getItem ("lomex_management_order"))
				let result2 = []
				result.forEach(item=>{
					let el = item
					if (order_column && item.value in order_column){
						el.number = order_column[item.value]
					}
					result2.push(el)
				})
				result2.sort((x, y) => x.number - y.number)
				let hidden_column = JSON.parse(localStorage.getItem ("lomex_management_hidden"))
				let result3 = []
				result2.forEach(item=>{
					if (!hidden_column || hidden_column.indexOf(item.value) == -1)
						result3.push(item)
				})
				this.table.header_table = result3
			},
            async updatePriceDiscount(){
                try {
                    const response = await ManagementProduct.updatePriceDiscount(this.transformProductsForRequest)
                    this.$toasted.show('Успешно, данные:' + "\n" + response.data.message, {
                      theme: 'bubble',
                      type: 'success',
                      position: 'top-center',
                      duration: 7000,
                    });
                    this.update_price_discount.dialog_pre_update_price_discount = false
                    this.update_price_discount.checked = false
    
                } catch(err){
                    this.$toasted.show(`Ошибка: ${err.response.data.message}`, {
                      theme: 'bubble',
                      type: 'error',
                      position: 'top-center',
                      duration: 3000,
                    });
                }
            },
            async checkPriceDiscountUpdate(){
                this.update_price_discount.checked = false
                this.update_price_discount.loading_check = true
                try{
                    let tmp = []
                    const response = await ManagementProduct.checkPriceDiscountForUpdate(this.transformProductsForRequest)
                    let resp_data = response.data.data
                    if (!response.data.error)
                        this.update_price_discount.checked = true
                    this.update_price_discount.pre_update_items.forEach(item => {
                        let index = resp_data.findIndex((el) => el.nmid == item.nmid)
                        if (index != -1){
                            item['change_discount'] = resp_data[index].change_discount
                            item['change_price'] = resp_data[index].change_price
                            item['checked'] = resp_data[index].checked
							item['old_finally_price'] = resp_data[index].old_finally_price
                            tmp.push(item)
                        }
                    })
                    if (tmp.length > 0){
                        this.update_price_discount.pre_update_items = tmp
                    }
                } catch(err){
                    console.log(err)
                }
                this.update_price_discount.loading_check = false
            },
			filterHasOrNot(item, value){
				if (item == 1)
					return value != null && value != 0
				if (item == 2)
					return value == null || value == 0
				return true
			},
			filterInArray(item, value){
				return item ? item.includes(value) : true;
			},
			filterSearchText(item, value){
				if(!item){
					return true
				}
				let value_low = value.toLowerCase()
				let item_low = item.toLowerCase()
				return value_low.includes(item_low)
			},
			isSelect(head, item){
				if (head == 'vp_promotion_1' || head == 'rentab_promotion_1')
					return item.select_1
				if (head == 'vp_promotion_2' || head == 'rentab_promotion_2')
					return item.select_2
				if (head == 'vp_promotion_3' || head == 'rentab_promotion_3')
					return item.select_3
				return false
			},
			itemClasses(item){
				if (
					!item.final_price || !item.cost_price ||
					item.final_price <= 0 || item.cost_price <= 0 || 
					Number(item.final_price) < Number(item.cost_price)
				){
					
					return ['red_row', `color_row_${item.id}`]
				}
				if (!item.vp || item.vp < 0)
					return ['orange_row', `color_row_${item.id}`]
				return `color_row_${item.id}`
			},
			returnFixPriceToData(){
				if (!this.old_data_fix_price || this.old_data_fix_price.length < 1)
					return
				this.old_data_fix_price.forEach(item => {
					let index = this.table.data.findIndex((el) => el.id == item.id)
					let fix_final_price = item.fix_final_price
					let fix_base_sale = item.fix_base_sale
					let fix_price = item.fix_price
					if (fix_final_price && fix_base_sale && fix_price){
						this.table.data[index].fix_final_price = fix_final_price
						this.table.data[index].fix_base_sale = fix_base_sale
						this.table.data[index].fix_price = fix_price
					}
				})
			},
			async fetchProductData(){
				this.table.loading = true
				try {
					const res = await ManagementProduct.fetchDetailTemplateManagement(this.templateId)
					this.table.data = res.data.last_state_data && res.data.last_state_data.length > 0 ? res.data.last_state_data : res.data.fresh_data 
					this.general_data = res.data.general_data
					this.promotion.data = res.data.promotions
					this.table.fresh_data = res.data.fresh_data && res.data.fresh_data.length > 0 ? res.data.fresh_data : []
					this.returnFixPriceToData()
					this.calculateValue()
					this.initFilters()
				} catch (err) {
					console.log(err)
				}
				this.table.loading = false
			},
			calculateValue(){
				let result = []
				this.table.data.forEach(item=>{
					let price = item.price_api_cost - item.price_api_cost * item.price_api_sales / 100
					let calc_commission = item.commission
					let prom_for_account = this.promotion.data.filter(el => el.account == item.account_id)
					let new_promo_commission = calc_commission
					prom_for_account.forEach(prom=>{
						let json_product_prom = prom.terms_of_promotion
						let price_for_promo;
						try{
							price_for_promo =  json_product_prom[item.nmid].price
						} catch (err){
							price_for_promo = null
						}
						if (price_for_promo > 0 && price < price_for_promo){
							let tmp_minimum_commission = prom.minimum_commission ? prom.minimum_commission : 0
							let tmp_delta_commission = prom.is_entered_to_promotion ? prom.is_entered_to_promotion : 0
							let tmp_new_promo_commission = calc_commission ? calc_commission - tmp_delta_commission: 0
							if (tmp_minimum_commission && tmp_new_promo_commission < tmp_minimum_commission)
								tmp_new_promo_commission = tmp_minimum_commission
							
							if (tmp_new_promo_commission && tmp_new_promo_commission < new_promo_commission){
								new_promo_commission = tmp_new_promo_commission
							}
						}
					})
					if (new_promo_commission && new_promo_commission < calc_commission)
						calc_commission = new_promo_commission
					item.calc_commission = calc_commission
					let commission = calc_commission ? price * calc_commission / 100 : 0 
					let redemption_percent = item.redemption_percent ?  item.redemption_percent : 0
					redemption_percent = (1 - item.redemption_percent / 100) * 50
					let cost_price = item.cost_price ? item.cost_price: 0
					let logistic = item.logistic ? item.logistic: 0
					let tax = item.tax ? price * item.tax / 100 : 0
					let acquiring = item.acquiring ? price * item.acquiring: 0

					let vp = price - cost_price - commission - logistic - tax - redemption_percent - acquiring
					let rentab = vp != 0 && price != 0 ? vp / price * 100 : 0
					let markup = vp != 0 && cost_price != 0 ? vp / cost_price * 100 : 0
					
					let spp = item.percent_spp ? item.percent_spp: 0
					item.vp = vp.toFixed(2)
					item.final_price = price.toFixed(2)
					item.markup = markup.toFixed(0)
					item.rentab = rentab.toFixed(0)
					item.spp = (price - price * spp / 100).toFixed(2)
					item.brand = item.catalog_item?.brand
					item.group = item.catalog_item.code1C_tag_group_name
					item.status = item.catalog_item.status_name
					item.promotion_1 = this.getPricePromotionByProduct(item, 1)
					item.promotion_2 = this.getPricePromotionByProduct(item, 2)
					item.promotion_3 = this.getPricePromotionByProduct(item, 3)
					let data_1 = this.getVPPromotionByProduct(item, 1)
					let data_2 = this.getVPPromotionByProduct(item, 2)
					let data_3 = this.getVPPromotionByProduct(item, 3)

					item.vp_promotion_1 = data_1[0]
					item.vp_promotion_2 = data_2[0]
					item.vp_promotion_3 = data_3[0]

					item.rentab_promotion_1 = data_1[1]
					item.rentab_promotion_2 = data_2[1]
					item.rentab_promotion_3 = data_3[1]

					result.push(item)
				})
				this.table.data = result
			},
			initFilters() {
				for (let col in this.filters) {
					if (Array.isArray(this.filters[col])){
						this.filters[col] = this.table.data.map((d) => { return d[col] }).filter(
							(value, index, self) => { return self.indexOf(value) === index }
						)
					}
				}
				this.activeFilters = Object.assign({}, this.filters)
			},
			toggleAll (col) {
				this.activeFilters[col] = this.table.data.map((d) => { return d[col] }).filter(
					(value, index, self) => { return self.indexOf(value) === index }
				)
			},
			async saveTemplateState (){
				this.saving_flag = true
				let json_data = JSON.stringify(this.table.data)
				try{
					const res = await ManagementProduct.saveLastStateTemplate(this.templateId, json_data)
				} catch (err){
					console.log(err)
				}
				this.saving_flag = false
			},
			async cleanTemplateState(){
				try{
					await ManagementProduct.cleanLastStateTemplate(this.templateId)
					this.old_data_fix_price = this.table.data
					this.table.data = []
					localStorage.removeItem('lomex_management_color_editing_field')
					await this.fetchProductData()
				} catch (err){
					console.log(err)
				}
			},
			async fetchWbAccountsByTemplate(){
				try{
					const resp = await ManagementProduct.fetchWbAccountByTemplates(this.templateId)
					this.promotion.list_wbaccount = resp.data
					if (resp.data.length > 0)
						this.promotion.choices_account = resp.data[0].value
				} catch(err){
					console.log(err)
				}
			},
			async openDialogActions(){
				if (this.promotion.list_wbaccount.length == 0){
					await this.fetchWbAccountsByTemplate()
				}
				this.promotion.dialog = true
			},
			filterPromotion(){
				this.promotion.filter_data = this.promotion.data.filter(
					item => item.account == this.promotion.choices_account
				)
			},
			promotionsByNumber(){
				this.filterPromotion()
				this.promotion.first_promotion = null
				this.promotion.second_promotion = null
				this.promotion.third_promotion = null

				this.promotion.filter_data.forEach(item => {
					if (item.number == 1)
						this.promotion.first_promotion = item
					else if (item.number == 2)
						this.promotion.second_promotion = item
					else if (item.number == 3)
						this.promotion.third_promotion = item
				})
			},
			openDialogCreatePromotion(number){
				this.promotion_create.dialog = true
				this.promotion_create.number = number
			},
			openDialogSearchPromotion(number){
				this.search_promotion.dialog = true
				this.search_promotion.number = number
			},
			openDialogUpdatePromotion(promotion_id){
				this.promotion_update.dialog = true
				this.promotion_update.promotion = promotion_id
			},
			async updatePromotion(){
				let fileToUpload = document.getElementById('promotion_file_update').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)
				formData.append('promotion', this.promotion_update.promotion)
				this.promotion_update.loading = true
				try {
					await ManagementProduct.updatePromotion(formData)
					await this.fetchProductData()
					this.promotionsByNumber()
				}
				catch (err){
					console.log(err)
				}
				this.promotion_update.dialog = false
				this.promotion_update.loading = false
			},
			async copyPromotion(promotion_id){
				this.copy_promotion_loading = true
				try {
					await ManagementProduct.copyPromotion({"promotion": promotion_id})
					await this.fetchProductData()
					this.promotionsByNumber()
				}
				catch (err){
					console.log(err)
				}
				this.copy_promotion_loading = false
			},
			async createPromotion(){
				let name = this.promotion_create.form.name
				let is_entered_to_promotion = this.promotion_create.form.is_entered_to_promotion
				let is_not_entered_to_promotion = this.promotion_create.form.is_not_entered_to_promotion
				let minimum_commission = this.promotion_create.form.minimum_commission
				let number = this.promotion_create.number
				let account = this.promotion.choices_account
				let fileToUpload = document.getElementById('promotion_file').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)
				if (name)
					formData.append('name', name)
				if (is_entered_to_promotion)
					formData.append('is_entered_to_promotion', is_entered_to_promotion)
				if (is_not_entered_to_promotion)
					formData.append('is_not_entered_to_promotion', is_not_entered_to_promotion)
				if (minimum_commission)
					formData.append('minimum_commission', minimum_commission)
				formData.append('number', number)
				formData.append('account', account)
				try {
					this.promotion_create.loading = true
					const response = await ManagementProduct.createPromotion(this.templateId, formData)
					await this.fetchProductData()
					this.promotionsByNumber()
					this.promotion_create.dialog = false
					this.$toasted.show(`Акция добавлена`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-center',
						duration: 3000,
					});
				}
				catch (err){
					this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
				this.promotion_create.loading = false
				
			},
			async deletePromotion(id){
				try{
					const response = await ManagementProduct.deletePromotion(id)
					await this.fetchProductData()
					this.promotionsByNumber()
					this.$toasted.show(`Акция успешно удалена`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-center',
						duration: 3000,
					});
				} catch (err){
					this.$toasted.show(`Ошибка удаления`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
			},
			getPricePromotionByProduct(item, number){
				let tmp = this.promotion.data.filter(el => el.account == item.account_id && el.number == number)
				if (tmp.length > 0){
					let json_product = tmp[0].terms_of_promotion
					try{
						return json_product[item.nmid].price
					} catch (err){
						return
					}
				}
				return 
			},
			getVPPromotionByProduct(item, number){
				let price = 0
				if (number == 1)
					price = item.promotion_1
				else if (number == 2)
					price = item.promotion_2
				else if (number == 3)
					price = item.promotion_3
				
				if (!price || price == 0)
					return [null, null]

				let tmp = this.promotion.data.filter(el => el.account == item.account_id && el.number == number)
				let delta_commission = 0
				let minimum_commission = 0
				if (tmp.length > 0){
					minimum_commission= tmp[0].minimum_commission ? tmp[0].minimum_commission : 0
					delta_commission = tmp[0].is_entered_to_promotion ? tmp[0].is_entered_to_promotion : 0
				}
				let cost_price = item.cost_price ? item.cost_price : 0
				let logistic = item.logistic ? item.logistic : 0
				let commission = item.commission ? item.commission - delta_commission: 0
				if (minimum_commission && commission < minimum_commission)
					commission = minimum_commission / 100
				else{
					commission = commission / 100
				}
				let redemption_percent = item.redemption_percent / 100 ? item.redemption_percent : 0
				redemption_percent = (1 - item.redemption_percent / 100) * 50			
				let tax = item.tax ? price * item.tax / 100 : 0
				let acquiring = item.acquiring ? price * item.acquiring: 0
				if (item.nmid == '82432565'){
					console.log("АКЦИЯ ВП", price, cost_price, commission * price, logistic, redemption_percent)
				}
				
				let vp = price - cost_price - commission * price - logistic - tax - redemption_percent - acquiring
				let rentab = vp != 0 ? vp / price * 100 : 0
				return [vp.toFixed(2), rentab.toFixed(0)]
			},
			selectPromotion(item, head){
				let number = 0
				if (head == 'vp_promotion_1' || head == 'rentab_promotion_1')
					number = 1 
				if (head == 'vp_promotion_2' || head == 'rentab_promotion_2')
					number = 2
				if (head == 'vp_promotion_3' || head == 'rentab_promotion_3')
					number = 3

				let index = this.table.data.indexOf(item)
				let old_select = this.table.data[index].select 
				let old_price_api_sales = this.table.data[index].price_api_sales 
				let old_price_api_cost = this.table.data[index].price_api_cost 
				let old_select_number = null
				if (this.table.data[index].select){
					if (this.table.data[index].select_1)
						old_select_number = 1
					if (this.table.data[index].select_2)
						old_select_number = 2
					if (this.table.data[index].select_3)
						old_select_number = 3
					this.table.data[index].select = false
					this.table.data[index].select_1 = false
					this.table.data[index].select_2 = false
					this.table.data[index].select_3 = false
				}
				this.table.data[index].select  = true
				let new_select_number = null
				if (number == 1){
					this.table.data[index].select_1  = true
					new_select_number = 1
				}
				if (number == 2){
					this.table.data[index].select_2  = true
					new_select_number = 2
				}
				if (number == 3){
					this.table.data[index].select_3  = true
					new_select_number = 3
				}
				let tmp = this.promotion.data.filter(el => el.account == item.account_id && el.number == number)
				let discount = null
				let plan_price = null
				if (tmp.length > 0){
					let json_product = tmp[0].terms_of_promotion
					try{
						discount = json_product[item.nmid].discount
						plan_price = json_product[item.nmid].price
					} catch (err){
						discount = null
					}
				}
				if (discount){
					plan_price = plan_price - 1
					let cur_discount = this.table.data[index].price_api_sales
					if (cur_discount <= 0){
						cur_discount = discount
					}
					let cur_price  = 100 * plan_price / (100 - cur_discount)					
					this.table.data[index].price_api_sales = cur_discount
					this.table.data[index].price_api_cost = Math.floor(cur_price)
				}
				this.list_actions.push({
					'type': 'select_promotion',
					'old_value': {
						"select": old_select, 
						"select_number": old_select_number, 
						"price_api_sales": old_price_api_sales, 
						"price_api_cost": old_price_api_cost
					},
					'new_value': {
						"select": true, 
						"select_number": new_select_number, 
						"price_api_sales": this.table.data[index].price_api_sales, 
						"price_api_cost": this.table.data[index].price_api_cost
					},
					'real_id': item.id,
				})
				this.calculateValue()
			},
			clearLocalStorageEditingFieldByNmid(id){
				var regex = new RegExp(`^[A-Za-z_]*${id}$`)
				if (localStorage.getItem("lomex_management_color_editing_field")){
					let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_editing_field"))
					for (let keys of Object.keys(cur_json)) {
						if (regex.test(keys))
							delete cur_json[keys]
					}
					localStorage.setItem('lomex_management_color_editing_field', JSON.stringify(cur_json));
				}
			},
			refreshItem(item){
				let index = this.table.data.findIndex((el) => el.id == item.id)
				let fix_final_price = this.table.data[index].fix_final_price
				let fix_base_sale = this.table.data[index].fix_base_sale
				let fix_price = this.table.data[index].fix_price
				let index2 = this.table.fresh_data.findIndex((el) => el.id == item.id)
				this.table.data[index] = this.table.fresh_data[index2]
				if (fix_final_price && fix_base_sale && fix_price){
					this.table.data[index].fix_final_price = fix_final_price
					this.table.data[index].fix_base_sale = fix_base_sale
					this.table.data[index].fix_price = fix_price
				}
				this.clearLocalStorageEditingFieldByNmid(item.id)
				this.calculateValue()
				location.reload()
			},
			changeCustomSearch(name){
				if(this.table.sortBy && this.table.sortBy[0] == name){
					if(this.table.sortDesc && this.table.sortDesc[0] == false){
						this.table.sortDesc = [true]
					}
					else{
						this.table.sortBy = []
						this.table.sortDesc = []
					}
				}
				else{
					this.table.sortDesc = [false]
					this.table.sortBy = [name]
				}
			},
			isCurrentSearchBy(name){
				return this.table.sortBy && this.table.sortBy[0] == name
			},
			isCurrentSearchDesc(name){
				return this.table.sortDesc && this.table.sortDesc[0]
			},
			sort(colName) {
				const index = this.table.sortBy.indexOf(colName);
				if (index === -1) {
					this.table.sortBy.push(colName);
					this.table.sortDesc.push(false);
				} else if (this.table.sortDesc[index]) {
					this.table.sortBy.splice(index, 1);
					this.table.sortDesc.splice(index, 1);
				} else {
					this.$set(this.table.sortDesc, index, true);
				}
			},
			openDialogPreUpdate(){
				this.dialog_alert = true
				this.update_price_discount.dialog_pre_update_price_discount = true
				this.update_price_discount.checked = false
				this.update_price_discount.pre_update_items = this.table.selected_items	
			},
			async createDelayedTask(){
				try {
					let dict = {
						"products": this.transformProductsForRequest,
						"date": this.update_price_discount.date_update,
						"time": this.update_price_discount.time_update,
						"template": this.templateId
					}
                    const response = await ManagementProduct.createDelayedTask(dict)
                    this.$toasted.show('Успешно', {
						theme: 'bubble',
						type: 'success',
						position: 'top-center',
						duration: 7000,
					});
                    this.update_price_discount.dialog_pre_update_price_discount = false
                    this.update_price_discount.checked = false
                } catch(err){
                    this.$toasted.show(`Ошибка: ${err.response.data.message}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
                }
			},
			calculateVolImage(t){
				t = Number(t)
				if (t >= 0 && t <= 143)
					return '01'
				if (t >= 144 && t <= 287)
					return '02'
				if (t >= 288 && t <= 431)
					return '03'
				if (t >= 432 && t <= 719)
					return '04'
				if (t >= 720 && t <= 1007)
					return '05'
				if (t >= 1008 && t <= 1061)
					return '06'
				if (t >= 1062 && t <= 1115)
					return '07'
				if (t >= 1116 && t <= 1169)
					return '08'
				if (t >= 1170 && t <= 1313)
					return '09'
				if (t >= 1314 && t <= 1601)
					return '10'
				if (t >= 1602 && t <= 1655)
					return '11'
				if (t >= 1656 && t <= 1919)
					return '12'
				if (t >= 1920 && t <= 2045)
					return '13'
				if (t >= 2046 && t <= 2189)
					return '14'
				if (t >= 2190 && t <= 2405) 
					return '15'
				return '16'
			},
			getImgPath (nmid) {
				let first_part = nmid.slice(0, -3)
				let second_part = nmid.slice(0, -5)
				let third_part = this.calculateVolImage(second_part)
				return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
			},
			bulkEditing(){
				this.table.selected_items.forEach(item=>{
					let index = this.table.data.indexOf(item)
					this.table.data[index][this.editing_data.select_type] = this.editing_data.new_value
					this.calculateValue()
					this.editing_data.dialog_editing_data = false
				})
			},
			moveColumnRight(e, header, number){
				e.stopPropagation()
				let index = this.table.header_table.findIndex(el => el.value === header);
				if (index == -1){
					return
				}
				try{
					let prev_el = this.table.header_table[index + 1]
					let cur_el = this.table.header_table[index]
					let cur_el_value = cur_el.value
					let prev_el_value = prev_el.value
					if (localStorage.getItem("lomex_management_order")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_order"))
						cur_json[prev_el_value] = cur_el.number
						cur_json[cur_el_value] = prev_el.number
						localStorage.setItem('lomex_management_order', JSON.stringify(cur_json));
					}
					else{
						localStorage.setItem('lomex_management_order', JSON.stringify({cur_el_value: prev_el.number, prev_el_value: cur_el.number}))
					}
				} catch (err){
					console.log(err)
				}		
				this.calculatedHeaderWithPromotions()
				this.initColorEditingFiedl()
				this.initColorRow()
				this.initColorCell()
			},
			moveColumnLeft(e, header, number){
				e.stopPropagation()
				if (number == 1){
					return
				}
				let index = this.table.header_table.findIndex(el => el.value === header);
				if (index == -1){
					return
				}
				let prev_el = this.table.header_table[index - 1]
				let cur_el = this.table.header_table[index]

				try{
					let cur_el_value = cur_el.value
					let prev_el_value = prev_el.value
					if (localStorage.getItem("lomex_management_order")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_order"))
						cur_json[prev_el_value] = cur_el.number
						cur_json[cur_el_value] = prev_el.number
						localStorage.setItem('lomex_management_order', JSON.stringify(cur_json));
					}
					else{
						localStorage.setItem('lomex_management_order', JSON.stringify({cur_el_value: prev_el.number, prev_el_value: cur_el.number}))
					}
				} catch (err){
					console.log(err)
				}		
				this.calculatedHeaderWithPromotions()
				this.initColorEditingFiedl()
				this.initColorRow()
				this.initColorCell()
			},
			drag(e) {
				let parent = e.target.parentNode
				let new_width = this.dragCord.cur_width + e.pageX - this.dragCord.start_x
				parent.style.width = new_width + 'px';
				parent.style.minWidth = new_width + 'px';
				
			},
			startDrag(e) {
				console.log("Кнопка нажата")
				if (e.which != 1) { // если клик правой кнопкой мыши
					return; // то он не запускает перенос
				}
				let cur_w = e.target.parentNode
				this.dragCord.cur_width = cur_w.offsetWidth
				this.dragCord.start_x  = e.pageX
			},
			saveWidth(header){
				console.log(header)
				let el = document.getElementsByClassName(`th_${header}`)
				console.log(localStorage.getItem ("lomex_management_width"))
				try{
					let w = el[0].offsetWidth
					if (localStorage.getItem("lomex_management_width")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_width"))
						cur_json[header] = w
						localStorage.setItem('lomex_management_width', JSON.stringify(cur_json));
					}
					else{
						localStorage.setItem('lomex_management_width', JSON.stringify({header: w}));
					}
				} catch (err){
					console.log(err)
				}				
			},
			initWidth(){
				this.table.header_table.forEach(item=>{
					let w_c = null;
					if (localStorage.getItem("lomex_management_width")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_width"))
						try{
							w_c = cur_json[item.value]
						} catch(err){
							w_c = null
						}
						if (w_c){
							let el = document.getElementsByClassName(`th_${item.value}`)
							try{
								el[0].style.width = w_c + 'px';
								el[0].style.minWidth = w_c + 'px';
							} catch(err){
								console.log(err)
							}
						}
					}	
				})
			},
			cleanLocalStorage(){
				localStorage.removeItem('lomex_management_width')
				location.reload()
			},
			cleanLocalStorageHidden(){
				localStorage.removeItem('lomex_management_hidden')
				location.reload()
			},
			cleanLocalStorageOrder(){
				localStorage.removeItem('lomex_management_order')
				location.reload()
			},
			cleanLocalStorageCell(){
				localStorage.removeItem('lomex_management_color_cell')
				localStorage.removeItem('lomex_management_color_row')
				location.reload()
			},
			hiddenColumn(e, header){
				try{
					let old_value = null
					if (localStorage.getItem("lomex_management_hidden")){
						old_value = JSON.parse(localStorage.getItem ("lomex_management_hidden"))
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_hidden"))
						if (cur_json.indexOf(header) == -1)
							cur_json.push(header)
						localStorage.setItem('lomex_management_hidden', JSON.stringify(cur_json));
					}
					else{
						localStorage.setItem('lomex_management_hidden', JSON.stringify([header]));
					}
					this.list_actions.push({
						'type': 'change_hidden_column',
						'old_value': old_value,
						'new_value': JSON.parse(localStorage.getItem ("lomex_management_hidden")),
					})
				} catch (err){
					console.log(err)
				}		
				this.calculatedHeaderWithPromotions()
			},
			ctrlZ () {
				if (this.list_actions.length < 1)
					return

				let last_action = this.list_actions.pop()
				if (last_action.type == 'change_simple_field'){
					let index = this.table.data.map(el => el.id ).indexOf(last_action.real_id)
					this.table.data[index][last_action.field] = last_action.old_value
					this.calculateValue()
					if (last_action.new_color && localStorage.getItem("lomex_management_color_editing_field")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_editing_field"))
						delete cur_json[last_action.id_cell]
						localStorage.setItem('lomex_management_color_editing_field', JSON.stringify(cur_json));
						let el = document.getElementById(last_action.id_cell)
						console.log(el)
						if (el)
							el.style.backgroundColor = '#ffffff'
						}
				} else if (last_action.type == 'change_color_cell'){
					if (localStorage.getItem("lomex_management_color_editing_field")){
						let cur_json = JSON.parse(localStorage.getItem ("lomex_management_color_cell"))
						if (!last_action.old_value)
							delete cur_json[last_action.id_cell]
						else
							cur_json[last_action.id_cell] = last_action.oldVal
						localStorage.setItem('lomex_management_color_cell', JSON.stringify(cur_json));
						let el = document.getElementById(last_action.id_cell)
						if (el)
							el.style.backgroundColor = last_action.old_value ? last_action.old_value: '#ffffff'
					}
				} else if (last_action.type == 'change_hidden_column'){
					if (localStorage.getItem("lomex_management_hidden")){
						let cur_json = last_action.old_value ? last_action.old_value : []
						localStorage.setItem('lomex_management_hidden', JSON.stringify(cur_json));
						this.calculatedHeaderWithPromotions()
					}
				} else if (last_action.type == 'select_promotion'){
					let index = this.table.data.map(el => el.id ).indexOf(last_action.real_id)
					this.table.data[index]['select'] = last_action.old_value.select
					this.table.data[index]['select_1'] = false
					this.table.data[index]['select_2'] = false
					this.table.data[index]['select_3'] = false
					if (last_action.old_value.select){
						if (last_action.old_value.select_number == 1)
							this.table.data[index]['select_1'] = true
						if (last_action.old_value.select_number == 2)
							this.table.data[index]['select_2'] = true
						if (last_action.old_value.select_number == 3)
							this.table.data[index]['select_3'] = true
					}
					this.table.data[index]['price_api_sales'] = last_action.old_value.price_api_sales
					this.table.data[index]['price_api_cost'] = last_action.old_value.price_api_cost
					this.calculateValue()
				}		
			},
			async exportToGoogleSheet(is_reset){
				this.loading_export_google = true
				let json_data = {"email": this.email_google}
				if (is_reset)
					json_data['is_reset'] = true
				try {
                    const response = await ManagementProduct.exportToGoogle(this.templateId, json_data)
					this.general_data.url_export_table = response.data.url_export_table
                    this.$toasted.show('Успешно', {
                      theme: 'bubble', type: 'success', position: 'top-center', duration: 7000,
                    });    
                } catch(err){
                    this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
                      theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                }
				this.loading_export_google = false
			},
			async change_auto_update(){
				try{
					await ManagementProduct.changeAutoUpdate(this.templateId)
				} catch(err){
					console.log(err)
				}
			},
			async change_is_open(){
				try{
					await ManagementProduct.changeIsOpen(this.templateId)
				} catch(err){
					console.log(err)
				}
			},
		},
		async mounted(){
			document.title = `Управление ценой (${this.templateId})`;
			this.$store.commit('setPageTitle', `Управление ценой (${this.templateId})`)
			document.addEventListener('keyup', this.keyupHandler)
			await this.fetchProductData()
			document.title = `Управление ценой (${this.general_data.name})`;
			this.$store.commit('setPageTitle', `Управление ценой (${this.general_data.name})`)
			this.calculatedHeaderWithPromotions()
			this.initWidth()
			this.initColorEditingFiedl()
			this.initColorRow()
			this.initColorCell()
			document.title = `Управление ценой (${this.general_data.name})`;
			this.$store.commit('setPageTitle', `Управление ценой (${this.general_data.name})`)
		},
		async updated(){
			this.initColorEditingFiedl()
			this.initColorRow()
			this.initColorCell()
			this.initWidth()
		}
	}
</script>

<style lang="scss" scoped>
	hr{
		width: 80%;
	}
	.image{
		height: 50px;
		width: 100%;
		margin: 5px auto 0 auto;
		margin-top: 5px;
		img{
			max-width: 100%;
			height: 50px;
			object-fit: cover;
		}
		.big_img{
			display: none;
			position: absolute;
			top: calc(50vh - 200px);
			left: calc(50% - 100px);
			right: 0;
			bottom: 0;
			z-index: 20;
			width: 300px;
			height: auto;
		}
		&:hover{
			.big_img{
				display: block;
			}
		}
	}
</style>

<style lang="scss">
.red_checked{
	background-color: #ebc3c3 !important;
}
.orange_checked{
	background-color: rgb(241, 201, 127);;
}
.th_catalog_item\.code1C_name{
	min-width: 200px;
}
.reset_color_template_management{
	background-color: #fff;
	padding: 10px;
	cursor: pointer;
	transition-duration: .3s;
	&:hover{
		background-color: #f6f6f6;
	}
}
.table_template_management{
	.td_custom{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	td{
		padding: 0 !important;
		text-align: center !important;
	}
	th:hover{
		.move-column	{
			display: block;
		}
		
	}
	.move-column{
		display: none;
		position: absolute;
		top: 100%;
		background-color: #fff;
		border: 1px solid black;
		left: 0;
		padding: 20px 0;
		border-radius: 8px;
		z-index: 10;
		color: black;
		box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
		div{
			white-space: nowrap;
			width: 100%;
			font-size: 16px;
			padding: 0 10px;
			cursor: pointer;
			&:not(:first-of-type){
				margin-top: 10px;	
			}
			&:hover{
				background-color: #a7a0a0;
			}
		}
	}
	.vertical_line{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 3px;
		background-color: blue;
		opacity: 0;
		&:hover{
			background-color: blue;
			opacity: 1;
			cursor: move;
		}
	}
	.test{
		width: 100%;
		height: 100%;
		cursor: pointer;
		font-style: italic;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.test::-webkit-outer-spin-button,
	.test::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
.table_template_management{
	td, th{
		border: 1px solid rgb(162, 160, 160);
		text-align: center !important;
	}
    .v-list--dense .v-list-item, .v-list-item--dense {
    min-height: 20px !important;
    height: 2rem;
    }

    .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: .5rem !important;
    }

    .v-list-item--link {
        transition: background-color .3s cubic-bezier(.25,.8,.5,1);
    }

    .v-list-item--link:hover {
        background-color: rgba(0,0,0, .13);
    }
}
.block_promotion__element{
	display: flex;
	align-items: center;
	margin-top: 35px;
	h2, span{
		color: black
	}
	span{
		font-size: 18px;
	}
	button span{
		font-size: 12px;
	}
}
.table_template_management{
	.check_in{
		background-color: #7373e3 !important; 
		div{
			background-color: #7373e3 !important; 
		}
		div:not(.v-input--selection-controls__input):not(.v-data-table__checkbox v-simple-checkbox):not(.v-input--selection-controls__ripple){
			background-color: #7373e3 !important;
		}
	}
	.green_el{
		background-color: greenyellow !important; 
		div{
			background-color: greenyellow !important; 
		}
		div:not(.v-input--selection-controls__input):not(.v-data-table__checkbox v-simple-checkbox):not(.v-input--selection-controls__ripple){
			background-color: greenyellow !important;
		}
	}
	.calc_test{
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration-style: dashed;
		text-decoration-line: underline;
		cursor: pointer;
	}
	.red_row{
		background-color: #ebc3c3 !important;
		td{
			background-color: #ebc3c3 !important;
		}
		div:not(.v-input--selection-controls__input):not(.v-data-table__checkbox v-simple-checkbox):not(.v-input--selection-controls__ripple){
			background-color: #ebc3c3 !important;
		}
	}
	.orange_row{
		background-color: rgb(241, 201, 127);
		td{
			background-color: rgb(241, 201, 127);
		}
		div:not(.v-input--selection-controls__input):not(.v-data-table__checkbox v-simple-checkbox):not(.v-input--selection-controls__ripple){
			background-color: rgb(241, 201, 127);
		}
	}
	.custom_search{
		padding: 5px 0;
		transition-duration: .3s;
		white-space: nowrap;
		cursor: pointer;
		i{
			font-size: 17px;
			transition-duration: .3s;
			opacity: 0;
		}
		&:hover{
			color: black;
			i{
				opacity: 1;
			}
			
		}
		&.active_search_by{
			color: black;
			i{
				color: black;
				opacity: 1;
			}
		}
		&.active_search_desc{
			i{
				transform: rotate(180deg);
			}
		}
	}
}
</style>